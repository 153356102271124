import Dropdown from 'afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useState } from 'react';

const generateYears = (startYear: number, endYear: number) => {
  const range = [];
  for (let year = startYear; year <= endYear; year++) {
    range.push(year);
  }
  return range;
};

interface YearCaptionProps {
  currentYear: number;
  yearRange: [number, number];
  onChangeYear?: (year: number) => void;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
}

export default function YearCaption({
  currentYear,
  yearRange,
  onChangeYear,
  customFocusScrollHandler,
  customSelectedScrollHandler,
}: YearCaptionProps) {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [yearToggle, setYearToggle] = useState(false);
  const years = generateYears(yearRange[0], yearRange[1]);

  const handleYearSelect = (year: number) => {
    setSelectedYear(year);
    setYearToggle(false);
    onChangeYear?.(year);
  };

  return (
    <div className='year-dropdown-wrapper'>
      <button
        type='button'
        className='flex flex-row items-center gap-4'
        onClick={() => setYearToggle(!yearToggle)}>
        <div className='whitespace-nowrap text-Header16 text-black700'>{selectedYear}년</div>
        <Icon name='chevron-down' size={16} color='black500' />
      </button>
      {yearToggle && (
        <Dropdown
          options={years.map((year) => `${year}년`)}
          selectedIndex={years.indexOf(selectedYear)}
          onSelect={(index) => handleYearSelect(years[index])}
          handleToggle={() => setYearToggle(!yearToggle)}
          wrapperClassName='year-dropdown'
          width={115}
          customFocusScrollHandler={customFocusScrollHandler}
          customSelectedScrollHandler={customSelectedScrollHandler}
        />
      )}
    </div>
  );
}

import type { HTMLAttributes } from 'react';
import { useTabContext } from '../contexts/tab-context';

export default function TabIndicator({ ...props }: HTMLAttributes<HTMLElement>) {
  const { indicatorStyle } = useTabContext();

  return (
    <span
      {...props}
      style={indicatorStyle}
      className='absolute bottom-0 inline-block h-2 bg-white900 p-0 transition-transform duration-250 ease-linear'
    />
  );
}

export const iconsMapping = {
  'afterdoc': () => import('../assets/icons/Afterdoc'),
'afterdoc-non': () => import('../assets/icons/AfterdocNon'),
'arrow-down-left-thick': () => import('../assets/icons/ArrowDownLeftThick'),
'arrow-line': () => import('../assets/icons/ArrowLine'),
'arrow-up-right-thick': () => import('../assets/icons/ArrowUpRightThick'),
'bold': () => import('../assets/icons/Bold'),
'bookmark': () => import('../assets/icons/Bookmark'),
'bookmarkempty': () => import('../assets/icons/Bookmarkempty'),
'chat-bubble': () => import('../assets/icons/ChatBubble'),
'chat-bubble-outline': () => import('../assets/icons/ChatBubbleOutline'),
'chat-video': () => import('../assets/icons/ChatVideo'),
'checkbox-blank-disabled': () => import('../assets/icons/CheckboxBlankDisabled'),
'checkbox-blank-outline': () => import('../assets/icons/CheckboxBlankOutline'),
'checkbox-default': () => import('../assets/icons/CheckboxDefault'),
'checkbox-marked': () => import('../assets/icons/CheckboxMarked'),
'checkbox-marked-disabled': () => import('../assets/icons/CheckboxMarkedDisabled'),
'checkbox-part-marked': () => import('../assets/icons/CheckboxPartMarked'),
'csv': () => import('../assets/icons/Csv'),
'exchange': () => import('../assets/icons/Exchange'),
'group': () => import('../assets/icons/Group'),
'h1': () => import('../assets/icons/H1'),
'h2': () => import('../assets/icons/H2'),
'h3': () => import('../assets/icons/H3'),
'horizontal': () => import('../assets/icons/Horizontal'),
'hr': () => import('../assets/icons/Hr'),
'hwp': () => import('../assets/icons/Hwp'),
'image-plus': () => import('../assets/icons/ImagePlus'),
'italic': () => import('../assets/icons/Italic'),
'kakaotalk': () => import('../assets/icons/Kakaotalk'),
'line': () => import('../assets/icons/Line'),
'linked': () => import('../assets/icons/Linked'),
'list-bulleted': () => import('../assets/icons/ListBulleted'),
'list-numbers': () => import('../assets/icons/ListNumbers'),
'logo-kakaotalk': () => import('../assets/icons/LogoKakaotalk'),
'meatball': () => import('../assets/icons/Meatball'),
'men': () => import('../assets/icons/Men'),
'more': () => import('../assets/icons/More'),
'pdf': () => import('../assets/icons/Pdf'),
'pin': () => import('../assets/icons/Pin'),
'pinned': () => import('../assets/icons/Pinned'),
'question-circle-outline': () => import('../assets/icons/QuestionCircleOutline'),
'quote': () => import('../assets/icons/Quote'),
'rectangle': () => import('../assets/icons/Rectangle'),
'redo': () => import('../assets/icons/Redo'),
'smart-doctor': () => import('../assets/icons/SmartDoctor'),
'smart-doctor-non': () => import('../assets/icons/SmartDoctorNon'),
'strikethrough': () => import('../assets/icons/Strikethrough'),
'underline': () => import('../assets/icons/Underline'),
'undo': () => import('../assets/icons/Undo'),
'women': () => import('../assets/icons/Women'),
'xlsx': () => import('../assets/icons/Xlsx'),
} as const;

export type CustomIconType = keyof typeof iconsMapping;

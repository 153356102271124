import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ModalProps } from 'afterdoc-design-system/components/Molecules/Modal/Modal';
import type { ModalListProps } from 'afterdoc-design-system/components/Molecules/Modal/Modal.type';
import { uniqueId } from 'lodash-es';
import type { ReactNode } from 'react';

type ButtonType = 'BOTH' | 'CONFIRM' | 'CUSTOM';

interface DefaultModalProps<T extends ButtonType> {
  id?: string;
  title?: string;
  width?: number | string;
  contents?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  buttonType?: T;
  buttonText?: T extends 'BOTH' ? [string, string] : T extends 'CONFIRM' ? string : never;
  bottomButtons?: T extends 'CUSTOM' ? ReactNode : never;
}

export const modalService = {
  defaultWarning<T extends ButtonType>({
    title = '저장하지 않고 나가시겠어요?',
    contents = '페이지 이탈 시 저장하지 않은 정보를 잃게 됩니다.',
    buttonType,
    bottomButtons,
    width,
    ...props
  }: DefaultModalProps<T>): string {
    const defaultButtonType = buttonType ?? 'BOTH';
    const uid = props?.id ?? uniqueId();

    const onConfirm = () => {
      if (props?.onClose) {
        props.onClose();
      }
      if (props?.onConfirm) {
        return props.onConfirm();
      }
      return modalService.pop();
    };

    const onCancel = () => {
      if (props?.onClose) {
        props.onClose();
      }
      if (props?.onCancel) {
        return props.onCancel();
      }
      return modalService.pop();
    };

    const Buttons = () => {
      if (defaultButtonType === 'BOTH') {
        const buttonText = props.buttonText as [string, string] | undefined;
        return (
          <>
            <ContainedButton className='w-[100px]' btnColor='red' onClick={onConfirm}>
              {buttonText?.[0] ?? '네'}
            </ContainedButton>
            <OutlinedButton className='w-[172px]' btnColor='secondary' onClick={onCancel}>
              {buttonText?.[1] ?? '아니오'}
            </OutlinedButton>
          </>
        );
      }

      if (defaultButtonType === 'CONFIRM') {
        return (
          <ContainedButton className='w-[280px]' btnColor='red' onClick={onConfirm}>
            {props.buttonText ?? '네'}
          </ContainedButton>
        );
      }

      return bottomButtons;
    };

    modalService.push({
      id: uid,
      contents,
      bottomButtons: <Buttons />,
      onClose: props.onClose,
      onCancel,
      onConfirm,
      titleProps: {
        title,
        adjacentChildren: {
          position: 'left',
          children: <Icon name='warning' size={24} color='red500' />,
        },
      },
    });

    return uid;
  },

  defaultSuccess<T extends ButtonType>({
    title,
    contents,
    buttonType,
    bottomButtons,
    ...props
  }: DefaultModalProps<T>): string {
    const defaultButtonType = buttonType ?? 'CONFIRM';
    const uid = props?.id ?? uniqueId();

    const onConfirm = () => {
      if (props?.onClose) {
        props.onClose();
      }
      if (props?.onConfirm) {
        return props.onConfirm();
      }
      return modalService.pop();
    };

    const onCancel = () => {
      if (props?.onClose) {
        props.onClose();
      }
      if (props?.onCancel) {
        return props.onCancel();
      }
      return modalService.pop();
    };

    const Buttons = () => {
      if (defaultButtonType === 'BOTH') {
        const buttonText = props.buttonText as [string, string] | undefined;
        return (
          <>
            <ContainedButton className='w-[100px]' btnColor='blue' onClick={onConfirm}>
              {buttonText?.[0] ?? '네'}
            </ContainedButton>
            <OutlinedButton className='w-[172px]' btnColor='secondary' onClick={onCancel}>
              {buttonText?.[1] ?? '아니오'}
            </OutlinedButton>
          </>
        );
      }

      if (defaultButtonType === 'CONFIRM') {
        return (
          <ContainedButton className='w-[280px]' btnColor='blue' onClick={onConfirm}>
            {props.buttonText ?? '네'}
          </ContainedButton>
        );
      }

      return bottomButtons;
    };

    modalService.push({
      id: uid,
      contents,
      onClose: props.onClose,
      onCancel,
      onConfirm,
      bottomButtons: <Buttons />,
      titleProps: {
        title: title ?? '',
      },
    });

    return uid;
  },

  push({ contents, width, bottomButtons, id, ...props }: ModalProps): string {
    const uniqId = id || uniqueId();

    const modalItem: ModalListProps = {
      id: uniqId,
      show: true,
      contents,
      bottomButtons,
      width,
      ...props,
    };

    const customEvent = new CustomEvent('pushModal', { detail: modalItem });
    document.dispatchEvent(customEvent);

    return uniqId;
  },

  pop() {
    const customEvent = new CustomEvent('popModal');
    document.dispatchEvent(customEvent);
  },

  popById(id: string) {
    const customEvent = new CustomEvent('popModalById', { detail: id });
    document.dispatchEvent(customEvent);
  },

  popAll() {
    const customEvent = new CustomEvent('popAllModals');
    document.dispatchEvent(customEvent);
  },
};

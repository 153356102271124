import type { IconButtonProps } from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import TextInput, {
  type TextInputProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import type { IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ChangeEvent, ReactNode, RefObject } from 'react';
import { forwardRef } from 'react';

export interface TextFieldSelectBoxProps extends TextInputProps {
  onClick: () => void;
  width?: number | string;
  errorText?: ReactNode;
  isToggle?: boolean;
  leftIconProps?: IconProps;
  rightIconButtonProps?: IconButtonProps;
  inputNotAllowed?: boolean;
  hasError?: boolean;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  wrapperRef?: RefObject<HTMLDivElement>;
  inputClassName?: string;
}

const TextFieldSelectBox = forwardRef<HTMLInputElement, Omit<TextFieldSelectBoxProps, 'onClick'>>(
  (
    {
      width,
      rightIconButtonProps,
      inputNotAllowed,
      value,
      onChange,
      wrapperRef,
      inputClassName,
      placeholder,
      isToggle = false,
      ...props
    },
    ref,
  ) => {
    return (
      <TextInput
        ref={ref}
        wrapperRef={wrapperRef}
        width={width}
        placeholder={placeholder}
        rightIconButtonProps={
          rightIconButtonProps ?? {
            icon: isToggle ? 'chevron-up' : 'chevron-down',
            size: 16,
            color: 'black200',
          }
        }
        value={value}
        onChange={onChange}
        inputClassName={inputClassName}
        inputNotAllowed={inputNotAllowed ?? true}
        {...props}
      />
    );
  },
);

TextFieldSelectBox.displayName = 'TextFieldSelectBox';

export default TextFieldSelectBox;

import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { CustomCaption } from 'afterdoc-design-system/components/Atoms/Calendar/components/CustomCaption';
import { useHolidays } from 'afterdoc-design-system/components/Atoms/Calendar/hooks/use-fetch-holidays';
import { ko } from 'date-fns/locale';
import dayjs from 'dayjs';
import { type RefObject, useEffect, useRef, useState } from 'react';
import { type DateRange, DayPicker, type DayPickerRangeProps } from 'react-day-picker';
import { v4 as uuidv4 } from 'uuid';
import './styles/WeekPicker.scss';

export interface WeekPickerProps
  extends Omit<DayPickerRangeProps, 'mode' | 'onSelect' | 'range' | 'onDayClick'> {
  id?: string;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
  handleToggle?: () => void;
  hasError?: boolean;
  monthRange?: [number, number];
  yearRange?: [number, number];
  ignoreRefs?: RefObject<HTMLElement>[];
  wrapperClassName?: string;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  onEscKeyDown?: () => void;
}

export default function WeekPicker({
  id,
  selectedDate,
  onChangeDate,
  handleToggle,
  hasError,
  monthRange,
  yearRange = [1900, dayjs().year()],
  wrapperClassName,
  ignoreRefs,
  disableBeforeDate,
  disableAfterDate,
  onEscKeyDown,
  ...props
}: WeekPickerProps) {
  const initialDateRef = useRef(selectedDate);
  const monthPickerRef = useRef<HTMLDivElement>(null);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>();
  const [selectedMonth, setSelectedMonth] = useState(dayjs(selectedDate).month());
  const [selectedYear, setSelectedYear] = useState(dayjs(selectedDate).year());

  const { holidays } = useHolidays(selectedYear, selectedMonth + 1);

  const modifiers = {
    holiday: holidays,
  };

  const getWeekRange = (date: Date): DateRange => {
    const startOfWeek = dayjs(date).startOf('week').toDate();
    const endOfWeek = dayjs(date).endOf('week').toDate();
    return { from: startOfWeek, to: endOfWeek };
  };

  const handleDayClick = (date: Date) => {
    if (!isDateDisabled(date)) {
      onChangeDate(date);
      setSelectedRange(getWeekRange(date));
      handleToggle?.();
    }
  };

  const isDateDisabled = (date: Date) => {
    if (disableAfterDate && date > disableAfterDate) return true;
    if (disableBeforeDate && date < disableBeforeDate) return true;
    return false;
  };

  const handleYearChange = (newYear: number) => {
    const initialYear = dayjs(initialDateRef.current).year();
    if (newYear !== initialYear) {
      setSelectedRange(undefined);
    } else {
      setSelectedRange(getWeekRange(initialDateRef.current));
    }
    setSelectedYear(newYear);
  };

  const handleMonthChange = (newMonth: number) => {
    const initialDate = dayjs(initialDateRef.current);
    if (dayjs(selectedDate).year() === initialDate.year() && newMonth === initialDate.month() + 1) {
      setSelectedRange(getWeekRange(initialDateRef.current));
    } else {
      setSelectedRange(undefined);
    }
    setSelectedMonth(newMonth - 1);
  };

  useEffect(() => {
    const cells = document.querySelectorAll('.WeekPicker .rdp-cell');
    const selectedCells = Array.from(cells).filter((cell) =>
      cell.querySelector('.rdp-day_selected'),
    );

    for (const [index, cell] of selectedCells.entries()) {
      cell.classList.add('rdp-cell-selected');

      if (index === 0) {
        cell.classList.add('rdp-cell-selected-start');
      } else if (index === selectedCells.length - 1) {
        cell.classList.add('rdp-cell-selected-end');
      }

      const dayToday = cell.querySelector('.rdp-day_today');
      const dayHoliday = cell.querySelector('.rdp-day_holiday');

      if (dayToday) {
        cell.classList.add('rdp-day-selected-today');
      }
      if (dayHoliday) {
        cell.classList.add('rdp-day-selected-holiday');
      }
    }

    return () => {
      for (const cell of cells) {
        cell.classList.remove(
          'rdp-cell-selected',
          'rdp-cell-selected-start',
          'rdp-cell-selected-end',
          'rdp-day-selected-today',
          'rdp-day-selected-holiday',
        );
      }
    };
  }, [selectedDate, selectedRange]);

  useEffect(() => {
    setSelectedRange(getWeekRange(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onEscKeyDown) {
        event.stopImmediatePropagation();
        onEscKeyDown();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscKeyDown]);

  if (handleToggle) {
    useClickOutside({
      id: id ?? uuidv4(),
      ref: monthPickerRef,
      contentRef: monthPickerRef,
      onClose: handleToggle,
      ignoreRefs: ignoreRefs ?? [],
    });
  }

  return (
    <div className={customTwMerge('WeekPicker', wrapperClassName)} ref={monthPickerRef}>
      <DayPicker
        showOutsideDays
        locale={ko}
        mode='range'
        selected={selectedRange}
        onDayClick={handleDayClick}
        month={new Date(selectedYear, selectedMonth)}
        components={{
          Caption: () => (
            <CustomCaption
              currentYear={selectedYear}
              currentMonth={selectedMonth}
              monthRange={[1, 12]}
              yearRange={[1900, dayjs().year()]}
              onChangeYear={handleYearChange}
              onChangeMonth={handleMonthChange}
            />
          ),
        }}
        modifiers={modifiers}
        modifiersClassNames={{
          holiday: 'rdp-day_holiday',
          disabled: 'rdp-day_disabled',
        }}
        disabled={[
          disableAfterDate
            ? {
                after: disableAfterDate,
              }
            : false,
          disableBeforeDate
            ? {
                before: disableBeforeDate,
              }
            : false,
        ]}
        {...props}
      />
    </div>
  );
}

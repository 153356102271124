import { useCustomEventListener } from 'afterdoc-design-system/hooks/use-custom-event-listener';
import type { Dispatch, SetStateAction } from 'react';
import type { ToastEventListenerType, ToastProps } from '../Toast.type';
import { useHandleToastList } from './use-handle-toast-list';

interface UseToastCustomEventListenerProps {
  toasts: ToastProps[];
  setToasts: Dispatch<SetStateAction<ToastProps[]>>;
}

export const useToastCustomEventListener = ({ setToasts }: UseToastCustomEventListenerProps) => {
  const { closeByIdToast, closeAllToast } = useHandleToastList({
    setToasts,
  });

  const addCb = (newItem: ToastProps) => {
    setToasts((prev) => prev.concat(newItem));

    if (newItem.autoCloseTimestamp || newItem.closeDelay) {
      const delay =
        newItem.closeDelay ??
        (newItem.autoCloseTimestamp ? newItem.autoCloseTimestamp - Date.now() : 0);

      if (delay > 0) {
        setTimeout(() => {
          setToasts((prev) => prev.filter((toast) => toast.id !== newItem.id));
        }, delay);
      }
    }
  };

  useCustomEventListener<ToastEventListenerType, ToastProps>({
    eventType: '[toast] show DefaultMsg',
    handler: addCb,
    depths: [addCb],
  });

  useCustomEventListener<ToastEventListenerType, ToastProps>({
    eventType: '[toast] show CustomMsg',
    handler: addCb,
    depths: [addCb],
  });

  useCustomEventListener<ToastEventListenerType, ToastProps>({
    eventType: '[toast] show ErrorMsg',
    handler: addCb,
    depths: [addCb],
  });

  useCustomEventListener<ToastEventListenerType, ToastProps>({
    eventType: '[toast] show SuccessMsg',
    handler: addCb,
    depths: [addCb],
  });

  useCustomEventListener<ToastEventListenerType, ToastProps>({
    eventType: '[toast] show LoadingMsg',
    handler: addCb,
    depths: [addCb],
  });

  useCustomEventListener<ToastEventListenerType, string>({
    eventType: '[toast] close Toast',
    handler: closeByIdToast,
    depths: [closeByIdToast],
  });

  useCustomEventListener<ToastEventListenerType, void>({
    eventType: '[toast] close all toast',
    handler: closeAllToast,
    depths: [closeAllToast],
  });
};

import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import YearCaption from 'afterdoc-design-system/components/Atoms/Calendar/components/CustomCaption/components/YearCaption';
import dayjs from 'dayjs';
import { type RefObject, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface MonthPickerProps {
  id?: string;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
  handleToggle?: () => void;
  yearRange?: [number, number];
  ignoreRefs?: RefObject<HTMLElement>[];
  wrapperClassName?: string;
  disabledDates?: Date[];
  onEscKeyDown?: () => void;
}

const months = Array.from({ length: 12 }, (_, i) => i + 1);

export default function MonthPicker({
  id,
  selectedDate,
  onChangeDate,
  handleToggle,
  yearRange = [1900, dayjs().year()],
  disabledDates = [],
  ignoreRefs,
  wrapperClassName,
}: MonthPickerProps) {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    selectedDate.getMonth() + 1,
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const initialDateRef = useRef(selectedDate);
  const monthPickerRef = useRef<HTMLDivElement>(null);

  const handleMonthSelect = (month: number) => {
    if (!isMonthDisabled(month)) {
      const newDate = new Date(selectedYear, month - 1, 1);
      onChangeDate(newDate);
      setSelectedMonth(month);
      handleToggle?.();
    }
  };

  const isMonthDisabled = (month: number) => {
    const currentYear = selectedDate.getFullYear();
    return disabledDates.some(
      (date) => date.getFullYear() === currentYear && date.getMonth() === month - 1,
    );
  };

  useEffect(() => {
    setSelectedMonth(dayjs(selectedDate).month() + 1);
  }, [selectedDate]);

  const handleYearChange = (newYear: number) => {
    const initialYear = initialDateRef.current.getFullYear();

    if (newYear !== initialYear) {
      setSelectedMonth(undefined);
    } else {
      setSelectedMonth(initialDateRef.current.getMonth() + 1);
    }
    setSelectedYear(newYear);
  };

  if (handleToggle) {
    useClickOutside({
      id: id ?? uuidv4(),
      ref: monthPickerRef,
      contentRef: monthPickerRef,
      onClose: handleToggle,
      ignoreRefs: ignoreRefs ?? [],
    });
  }

  return (
    <div
      className={customTwMerge(
        'MonthPicker flex w-full flex-col gap-20 rounded-r16 bg-white50 p-20 shadow-modal',
        wrapperClassName,
      )}
      ref={monthPickerRef}>
      <YearCaption
        currentYear={dayjs(selectedDate).year()}
        onChangeYear={handleYearChange}
        yearRange={yearRange}
      />

      <div className='mt-4 grid w-full grid-cols-4 grid-rows-3 gap-4'>
        {months.map((month) => (
          <button
            type='button'
            key={month}
            onClick={() => handleMonthSelect(month)}
            disabled={isMonthDisabled(month)}
            className={customTwMerge(
              'flex items-center justify-center rounded-r10 text-Header14 disabled:bg-white200 disabled:text-disabled',
              selectedMonth === month
                ? 'border border-blue500 bg-blue200'
                : 'bg-white50 text-black500',
            )}
            style={{
              width: 87,
              height: 84,
            }}>
            {month}월
          </button>
        ))}
      </div>
    </div>
  );
}

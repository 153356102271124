import type { ContextMenuListProps } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import {
  type UseHandleContextMenuListProps,
  useHandleContextMenuList,
} from 'afterdoc-design-system/components/Molecules/ContextMenu/functions/use-handle-context-menu-list';
import { useEffect } from 'react';

export const useContextMenuCustomEventListener = ({
  contextMenuList,
  setContextMenuList,
}: UseHandleContextMenuListProps) => {
  const { popLatestContextMenu, popContextMenuById, popAll } = useHandleContextMenuList({
    contextMenuList,
    setContextMenuList,
  });

  const cb =
    <T>(event: Event) =>
    (handler: (e: T) => void) => {
      const eventData = event as CustomEvent<T>;
      return handler(eventData.detail);
    };

  const pushCb = (e: Event) =>
    cb<ContextMenuListProps>(e)((newContextMenu) =>
      setContextMenuList((prev) => prev.concat(newContextMenu)),
    );

  const popCb = (e: Event) => cb(e)(popLatestContextMenu);
  const popByIdCb = (e: Event) => cb<string>(e)(popContextMenuById);
  const popAllCb = (e: Event) => cb(e)(popAll);

  useEffect(() => {
    document.addEventListener('contextMenuPush', pushCb);
    return () => document.removeEventListener('contextMenuPush', pushCb);
  }, [pushCb]);

  useEffect(() => {
    document.addEventListener('contextMenuPop', popCb);
    return () => document.removeEventListener('contextMenuPop', popCb);
  }, [popCb]);

  useEffect(() => {
    document.addEventListener('contextMenuPopById', popByIdCb);
    return () => document.removeEventListener('contextMenuPopById', popByIdCb);
  }, [popByIdCb]);

  useEffect(() => {
    document.addEventListener('contextMenuPopAll', popAllCb);
    return () => document.removeEventListener('contextMenuPopAll', popAllCb);
  }, [popAllCb]);
};

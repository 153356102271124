import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useDropdownKeyboardEvent } from 'afterdoc-design-system/components/Atoms/Dropdown/hooks/use-dropdown-keyboard-events';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  type CSSProperties,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface DropdownProps {
  id?: string;
  options: string[];
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  handleToggle: () => void;
  redTextIndices?: number[];
  wrapperClassName?: string;
  optionsClassName?: string;
  width?: number | string;
  wrapperStyle?: CSSProperties;
  optionsStyle?: CSSProperties;
  ignoreRefs?: RefObject<HTMLElement>[];
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  focusedIndex?: number;
  onFocusChange?: (index: number) => void;
  disabledHandleKeyDown?: boolean;
  hasFixedHeight?: boolean;
}

export default function Dropdown({
  id,
  options,
  selectedIndex,
  onSelect,
  handleToggle,
  redTextIndices = [],
  wrapperClassName,
  optionsClassName,
  width,
  wrapperStyle,
  optionsStyle,
  ignoreRefs,
  customFocusScrollHandler,
  customSelectedScrollHandler,
  focusedIndex: initialFocusedIndex,
  onFocusChange,
  hasFixedHeight = true,
}: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
    scrollToHeight: (height: number, behavior?: ScrollBehavior) => void;
  } | null>(null);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(initialFocusedIndex);

  const defaultFocusScrollHandler = useCallback(() => {
    if (focusedIndex === undefined || !scrollbarRef.current) return;

    const focusedElement = !hasFixedHeight
      ? (contentRef.current?.querySelectorAll('li')?.[focusedIndex] as HTMLElement | undefined)
      : null;

    const scrollPosition = focusedElement ? focusedElement.offsetTop : focusedIndex;

    const scrollMethod = !hasFixedHeight ? 'scrollToHeight' : 'scrollTo';

    scrollbarRef.current[scrollMethod](scrollPosition, 'auto');
  }, [focusedIndex, hasFixedHeight]);

  const defaultSelectedScrollHandler = useCallback(() => {
    const selectedElement = contentRef.current?.querySelector(
      `[aria-selected='true']`,
    ) as HTMLElement | null;

    if (!selectedElement || !scrollbarRef.current) return;

    const scrollPosition = hasFixedHeight
      ? Number(selectedElement.getAttribute('item-index'))
      : selectedElement.offsetTop;

    const scrollMethod = hasFixedHeight ? 'scrollTo' : 'scrollToHeight';

    scrollbarRef.current[scrollMethod](scrollPosition, 'auto');
  }, [selectedIndex, hasFixedHeight]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleToggle();
        event.stopImmediatePropagation();
        return;
      }
      event.preventDefault();

      if (!contentRef.current) return;

      useDropdownKeyboardEvent({
        keycode: event.key,
        listEl: contentRef.current,
        onEnter: handleEnter,
        onEsc: handleToggle,
        focusedIndex,
        setFocusedIndex,
      });
    },
    [focusedIndex, handleToggle],
  );

  const handleEnter = (selectedValue: string) => {
    if (!options) return;

    const selected = options.find((item) => item === selectedValue);

    if (selected) {
      const selectedIdx = options.indexOf(selected);
      onSelect?.(selectedIdx);
      setFocusedIndex(selectedIdx);
    }
  };

  useClickOutside({
    id: id ?? uuidv4(),
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
    ignoreRefs: ignoreRefs ?? [],
  });

  useEffect(() => {
    const handleFocus = () => {
      if (dropdownRef.current && !dropdownRef.current.contains(document.activeElement)) {
        handleToggle();
      }
    };

    window.addEventListener('focus', handleFocus, true);

    return () => {
      window.removeEventListener('focus', handleFocus, true);
    };
  }, [dropdownRef, handleToggle]);

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter'
      ) {
        handleKeyDown(event);
      }
    };

    window.addEventListener('keydown', keydownListener, true);
    return () => {
      window.removeEventListener('keydown', keydownListener, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!focusedIndex) return;

    const position = customFocusScrollHandler
      ? customFocusScrollHandler(focusedIndex)
      : focusedIndex;

    if (scrollbarRef.current) {
      const focusedElement = !hasFixedHeight
        ? (contentRef.current?.querySelectorAll('li')?.[position] as HTMLElement | undefined)
        : null;

      const scrollPosition = focusedElement ? focusedElement.offsetTop : focusedIndex;

      const scrollMethod = hasFixedHeight ? 'scrollTo' : 'scrollToHeight';
      scrollbarRef.current[scrollMethod](scrollPosition, 'auto');
    } else {
      defaultFocusScrollHandler();
    }
  }, [focusedIndex, customFocusScrollHandler, defaultFocusScrollHandler, hasFixedHeight]);

  useEffect(() => {
    if (selectedIndex === undefined) return;

    const position = customSelectedScrollHandler
      ? customSelectedScrollHandler(selectedIndex)
      : selectedIndex;

    if (scrollbarRef.current) {
      const selectedElement = !hasFixedHeight
        ? (contentRef.current?.querySelectorAll('li')?.[position] as HTMLElement | undefined)
        : null;

      const scrollPosition = selectedElement ? selectedElement.offsetTop : selectedIndex;

      const scrollMethod = hasFixedHeight ? 'scrollTo' : 'scrollToHeight';
      scrollbarRef.current[scrollMethod](scrollPosition, 'auto');
    } else {
      defaultSelectedScrollHandler();
    }
  }, [selectedIndex, customSelectedScrollHandler, defaultSelectedScrollHandler, hasFixedHeight]);

  useEffect(() => {
    if (focusedIndex === undefined && options.length > 0) {
      setFocusedIndex(selectedIndex ?? 0);
    }
  }, [options.length, selectedIndex, focusedIndex]);

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        ref={contentRef}
        className={customTwMerge(
          'absolute z-10 w-full select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
          wrapperClassName,
        )}
        data-ignore-click-outside={true}
        style={{
          height: 32 * Math.min(options.length, 5) + 8,
          width: SHARED_UTILS.css.getCssSizeValue(width),
          ...wrapperStyle,
        }}>
        <Scrollbar ref={scrollbarRef}>
          <ul aria-orientation='vertical' aria-labelledby='options-menu'>
            {options.map((option, index) => (
              <li
                style={optionsStyle}
                key={index}
                tabIndex={index}
                onClick={() => {
                  onSelect?.(index);
                }}
                onMouseEnter={() => onFocusChange?.(index)}
                className={customTwMerge(
                  `block cursor-pointer px-10 py-7 focus:bg-blue50 focus:outline-none ${
                    redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
                  } flex items-center justify-between hover:bg-blue50`,
                  selectedIndex === index ? 'text-Header12' : 'text-Body12',
                  optionsClassName,
                )}
                item-index={index}
                aria-selected={selectedIndex === index ? 'true' : undefined}>
                <span>{option}</span>
                {selectedIndex === index && (
                  <Icon name='done' color='blue500' size={16} className='flex-shrink-0' />
                )}
              </li>
            ))}
          </ul>
        </Scrollbar>
      </div>
    </div>
  );
}

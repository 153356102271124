import { useAnimationToggle } from 'afterdoc-design-system/hooks/use-animation-toggle';
import { usePointerEvent } from 'afterdoc-design-system/hooks/use-pointer-event';
import { usePositioningByCursor } from 'afterdoc-design-system/hooks/use-positioning-by-cursor';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { type PropsWithChildren, useId } from 'react';
import Tooltip from './Tooltip';
import type { CursorTooltipProps } from './Tooltip.type';

export default function CursorTooltip({
  children,
  show = true,
  wrapperProps = {},
  ...props
}: PropsWithChildren<CursorTooltipProps>) {
  const { style: wrapperStyle, ...restWrapperProps } = wrapperProps;
  const { id, style, ...restTooltipProps } = props;
  const uid = useId();
  const tooltipId = id || uid;
  const zIndex = style?.zIndex || 101;

  const {
    mounted,
    trigger: opacity,
    onShow,
    onHide,
  } = useAnimationToggle({ transitionDuration: 150 });
  const { cursorPosition, onPointerEnter, onPointerLeave, onPointerMove } = usePointerEvent();
  const { style: positionByCursor } = usePositioningByCursor({
    cursorPosition,
    contentId: mounted ? tooltipId : undefined,
  });

  const onPointerEnterHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onPointerEnter(e);
    onShow();
  };

  const onPointerLeaveHandler = () => {
    onPointerLeave();
    onHide();
  };

  const onPointerMoveHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onPointerMove(e);
  };

  return (
    <>
      <div
        {...restWrapperProps}
        onPointerEnter={onPointerEnterHandler}
        onPointerMove={onPointerMoveHandler}
        onPointerLeave={onPointerLeaveHandler}
        style={{
          display: 'inline-block',
          cursor: show ? 'pointer' : 'unset',
          ...wrapperStyle,
        }}>
        {children}
      </div>
      {mounted && show && (
        <Portal>
          <Tooltip
            {...restTooltipProps}
            id={uid}
            style={{
              ...positionByCursor,
              position: 'fixed',
              transition: 'opacity 150ms ease',
              zIndex,
              opacity,
              ...style,
            }}
          />
        </Portal>
      )}
    </>
  );
}

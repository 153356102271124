import { getCssSizeValue } from './get-css-size-value';

export const css = {
  /**
   * 주어진 너비 값을 CSS 너비 값으로 변환합니다.
   *
   * 이 함수는 너비 값이 숫자인 경우 'px' 단위를 추가하고,
   * 문자열인 경우 그대로 반환합니다. 너비 값이 주어지지 않은 경우
   * 기본값으로 '100%'를 반환합니다.
   *
   * @param {number | string} [width] - 너비 값. 숫자 또는 문자열일 수 있습니다.
   * @returns {string} CSS에서 사용할 수 있는 너비 값.
   *
   * @example
   * // 숫자 100을 '100px'로 변환합니다.
   * getCssSizeValue(100); // '100px'
   *
   * @example
   * // 문자열 '50%'를 그대로 반환합니다.
   * getCssSizeValue('50%'); // '50%'
   *
   * @example
   * // 너비 값이 주어지지 않은 경우 '100%'를 반환합니다.
   * getCssSizeValue(); // '100%'
   *
   * @example
   * // 'fit-content'를 그대로 반환합니다.
   * getCssSizeValue('fit-content'); // 'fit-content'
   */
  getCssSizeValue,
};

import { api } from './api';
import { color } from './color';
import { css } from './css';
import { jotai } from './jotai';
import { text } from './text';

export const SHARED_UTILS = {
  text,
  color,
  jotai,
  css,
  api,
};

import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ProgressBar, { type ProgressBarProps } from './ProgressBar';

export default function ProgressBarManager() {
  const [progressBar, setProgressBar] = useState<ProgressBarProps>();

  useEffect(() => {
    const handleShow = (event: CustomEvent<ProgressBarProps>) => {
      setProgressBar(event.detail);
    };

    const handleHide = () => {
      setProgressBar(undefined);
    };

    document.addEventListener('showProgressBar', handleShow as EventListener);
    document.addEventListener('hideProgressBar', handleHide);

    return () => {
      document.removeEventListener('showProgressBar', handleShow as EventListener);
      document.removeEventListener('hideProgressBar', handleHide);
    };
  }, []);

  if (!progressBar) return null;

  return (
    <Portal>
      <div className='fixed right-40 bottom-40 z-[10000] w-[630px]'>
        <ProgressBar key={uuidv4()} {...progressBar} />
      </div>
    </Portal>
  );
}

import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import loadingSinnerAnimationData from 'afterdoc-design-system/assets/lotties/loading-spinner.json';
import type { LottiePlayer } from 'lottie-web';
import { type CSSProperties, useEffect, useRef, useState } from 'react';

export interface LoadingSpinnerProps {
  width?: number;
  height?: number;
  style?: CSSProperties;
  className?: string;
}

const getLottiePath = () => {
  try {
    return loadingSinnerAnimationData;
  } catch (_error) {
    console.error('Lottie file not found');
    return null;
  }
};
export default function LoadingSpinner({
  width = 100,
  height = 100,
  style,
  className,
}: LoadingSpinnerProps) {
  const CustomIconComponent = getLottiePath();

  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current && CustomIconComponent) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CustomIconComponent,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });

      lottie.setSpeed(3);

      return () => animation.destroy();
    }
  }, [lottie, CustomIconComponent]);

  return (
    <div
      className={customTwMerge('flex-center select-none', className)}
      role='status'
      style={{
        width: SHARED_UTILS.css.getCssSizeValue(width),
        height: SHARED_UTILS.css.getCssSizeValue(height),
        ...style,
      }}>
      <div ref={ref} className='h-full w-full' />
    </div>
  );
}

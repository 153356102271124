import {
  type ContextMenuListProps,
  type OffsetOption,
  PositionType,
} from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import { useFocusInContent } from 'afterdoc-design-system/components/Molecules/ContextMenu/functions/use-focus-in-content';
import { useHandleClickBackdrop } from 'afterdoc-design-system/components/Molecules/ContextMenu/functions/use-handle-click-backdrop';
import { useCheckTargetOverflowScreen } from 'afterdoc-design-system/hooks/use-check-target-overflow-screen';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { type CSSProperties, useEffect, useRef, useState } from 'react';

interface ContentPosition {
  top: number;
  left: number;
}

export const ContextMenuItem = ({
  id,
  parent,
  content,
  onClose,
  positionType,
  backdropStyle,
  portalStyle,
  offsetOption,
}: ContextMenuListProps) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { backdropRef, handleMouseDown, handleMouseUp } = useHandleClickBackdrop(onClose);
  const [contentPosition, setContentPosition] = useState<Pick<CSSProperties, 'top' | 'left'>>();

  function getElementPos(parent: Element, offsetOption: OffsetOption): ContentPosition {
    const { top, left } = parent.getBoundingClientRect();
    const { xOffset, yOffset } = offsetOption;
    return { top: top + yOffset, left: left + xOffset };
  }

  function getMousePos(mouseEvent: React.MouseEvent, offsetOption: OffsetOption): ContentPosition {
    const { clientX, clientY } = mouseEvent;
    const { xOffset, yOffset } = offsetOption;
    return { top: clientY + yOffset, left: clientX + xOffset };
  }

  function setPositionByType(
    parent: Element,
    type: PositionType,
    offsetOption: OffsetOption,
  ): ContentPosition {
    offsetOption.event.preventDefault();
    if (!offsetOption.event || type === PositionType.ELEMENT_POSITION) {
      return getElementPos(parent, offsetOption);
    }
    return getMousePos(offsetOption.event, offsetOption);
  }

  useEffect(() => {
    const { clientWidth, clientHeight } = targetRef.current || { clientWidth: 0, clientHeight: 0 };

    if (!parent || !positionType || !offsetOption) return;

    const position = setPositionByType(parent, positionType, offsetOption);
    const { isWidthOverflow, isHeightOverflow } = useCheckTargetOverflowScreen({
      clientX: position.left,
      clientY: position.top,
      clientWidth,
      clientHeight,
    });

    if (isWidthOverflow) {
      setContentPosition({ top: `${position.top}px`, left: `${position.left - clientWidth}px` });
      return;
    }

    if (isHeightOverflow) {
      setContentPosition({ top: `${position.top - clientHeight}px`, left: `${position.left}px` });
      return;
    }

    setContentPosition({ top: `${position.top}px`, left: `${position.left}px` });
  }, [targetRef]);

  useFocusInContent({ id });

  useEffect(() => {
    window.addEventListener('resize', onClose);

    return () => {
      window.removeEventListener('resize', onClose);
    };
  }, []);

  return (
    <Portal style={portalStyle}>
      <div
        ref={backdropRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{ width: '100%', height: '100%', ...backdropStyle }}>
        <div
          ref={targetRef}
          id={id}
          style={{ ...contentPosition, opacity: contentPosition ? 1 : 0, position: 'absolute' }}>
          {content}
        </div>
      </div>
    </Portal>
  );
};

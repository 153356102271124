import { type ReactNode, createContext, useCallback } from 'react';

export type ValueType = string | number;

interface RadioGroupContextType {
  value?: ValueType;
  onChange: (value: ValueType) => void;
  name: string;
}

export const RadioGroupContext = createContext<RadioGroupContextType | undefined>(undefined);

interface RadioGroupProps {
  children: ReactNode;
  value?: ValueType;
  onChange?: (value: ValueType) => void;
  name: string;
}

export const RadioGroup = ({ children, value, onChange, name }: RadioGroupProps) => {
  const handleChange = useCallback(
    (newValue: ValueType) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  return (
    <RadioGroupContext.Provider value={{ value, onChange: handleChange, name }}>
      {children}
    </RadioGroupContext.Provider>
  );
};

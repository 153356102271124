import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import SvgLogoColor from 'afterdoc-design-system/assets/logos/LogoColor';
import SvgLogoColorLogotype from 'afterdoc-design-system/assets/logos/LogoColorLogotype';
import SvgLogoColorSymbol from 'afterdoc-design-system/assets/logos/LogoColorSymbol';
import SvgLogoWhite from 'afterdoc-design-system/assets/logos/LogoWhite';
import SvgLogoWhiteLogotype from 'afterdoc-design-system/assets/logos/LogoWhiteLogotype';
import SvgLogoWhiteSymbol from 'afterdoc-design-system/assets/logos/LogoWhiteSymbol';

const LogoImages = {
  LogoColor: SvgLogoColor,
  LogoColorSymbol: SvgLogoColorSymbol,
  LogoColorLogotype: SvgLogoColorLogotype,
  LogoWhite: SvgLogoWhite,
  LogoWhiteSymbol: SvgLogoWhiteSymbol,
  LogoWhiteLogotype: SvgLogoWhiteLogotype,
} as const;

export interface LogoProps {
  name: keyof typeof LogoImages;
  size: number;
  className?: string;
}

const getLogoIcon = (name: LogoProps['name']) => {
  return LogoImages[name] || null;
};

export default function Logo({ name, size, className }: LogoProps) {
  const CustomIconComponent = getLogoIcon(name);
  if (!CustomIconComponent) return null;

  return (
    <div style={{ width: size }} className={customTwMerge('inline-block h-auto', className)}>
      <CustomIconComponent width='100%' height='100%' preserveAspectRatio='xMidYMid meet' />
    </div>
  );
}

export const convertCountryCodeToEmoji = (countryCode: string): string => {
  if (!countryCode || countryCode.length !== 2) {
    return '';
  }

  const base = 0x1f1e6 - 'A'.charCodeAt(0);

  const firstCharCode = countryCode.charCodeAt(0);
  const secondCharCode = countryCode.charCodeAt(1);

  if (
    firstCharCode < 'A'.charCodeAt(0) ||
    firstCharCode > 'Z'.charCodeAt(0) ||
    secondCharCode < 'A'.charCodeAt(0) ||
    secondCharCode > 'Z'.charCodeAt(0)
  ) {
    return '';
  }

  return String.fromCodePoint(base + firstCharCode, base + secondCharCode);
};

import { birthdateHyphenText } from './birthdate-hypen-text';
import { calculateByteSize } from './calculate-byte-size';
import { checkStringFileSize } from './check-string-file-size';
import { convertCountryCodeToEmoji } from './convert-country-code-to-emoji';
import { extractTextFromChildren } from './extract-text-from-children';
import { makeTextColorWithBgColor } from './make-text-color-with-bg-color';
import { phoneHyphenText } from './phone-hyphen-text';
import { truncateText } from './truncate-text';

export const text = {
  /**
   * 주어진 두 글자의 국가 코드를 해당하는 이모지 국기로 변환합니다.
   *
   * 이 함수는 두 글자의 국가 코드(ISO 3166-1 alpha-2)를 받아서
   * 해당하는 이모지 국기로 변환합니다. 입력이 대문자 알파벳으로 구성된
   * 유효한 두 글자 국가 코드가 아닌 경우, 함수는 빈 문자열을 반환합니다.
   *
   * @param {string} countryCode - 변환할 두 글자의 국가 코드.
   * @returns {string} 주어진 국가 코드에 해당하는 이모지 국기, 또는 입력이 유효하지 않을 경우 빈 문자열.
   *
   * @example
   * // 미국의 경우 '🇺'를 반환합니다
   * convertCountryCodeToEmoji('US');
   *
   * @example
   * // 유효하지 않은 입력의 경우 빈 문자열을 반환합니다
   * convertCountryCodeToEmoji('XYZ');
   */
  convertCountryCodeToEmoji,

  /**
   * 주어진 React 노드에서 텍스트를 추출합니다.
   *
   * 이 함수는 주어진 React 노드에서 텍스트를 재귀적으로 추출하여 문자열로 반환합니다.
   *
   * @param {React.ReactNode} children - 텍스트를 추출할 React 노드.
   * @returns {string} 추출된 텍스트 문자열.
   *
   * @example
   * // <div>Hello <strong>World</strong></div>에서 "Hello World"를 추출합니다.
   * extractTextFromChildren(<div>Hello <strong>World</strong></div>);
   */
  extractTextFromChildren,

  /**
   * 주어진 배경색에 대비되는 텍스트 색상을 생성합니다.
   *
   * 이 함수는 주어진 배경색에 대해 가독성을 높이기 위한 적절한 텍스트 색상을 반환합니다.
   *
   * @param {string} bgColor - 배경색 (예: '#FFFFFF').
   * @returns {string} 대비되는 텍스트 색상 (예: '#000000').
   *
   * @example
   * // 배경색이 '#FFFFFF'인 경우 '#000000'을 반환합니다.
   * makeTextColorWithBgColor('#FFFFFF');
   */
  makeTextColorWithBgColor,

  /**
   * 주어진 문자열을 지정된 길이로 자르고, 필요한 경우 끝에 "..."을 추가합니다.
   *
   * 이 함수는 주어진 문자열이 지정된 길이를 초과하는 경우, 해당 문자열을 자르고 끝에 "..."을 추가하여 반환합니다.
   *
   * @param {string} text - 자를 문자열.
   * @param {number} length - 최대 길이.
   * @returns {string} 자른 문자열.
   *
   * @example
   * // 문자열 'Hello World'를 최대 길이 5로 자르고 'He...'를 반환합니다.
   * truncateText('Hello World', 5);
   */
  truncateText,

  /**
   * 주어진 문자열 (숫자) 길이에 따라 하이픈(-) 을 추가합니다.
   *
   * 이 함수는 주어진 문자열의 길이에 따라 하이픈(-) 을 추가하여 전화번호 형태로 반환합니다
   *
   * @param {string} text - 휴대폰 정규식에 따라 자동 하이픈 처리할 문자열
   * @return {string} 휴대펀 정규식 형태로 변환된 문자열
   *
   * @example
   * // 문자열 길이가 11인 경우 '010-1234-5678'를 반환합니다.
   * truncateText('01012345678');
   *
   */
  phoneHyphenText,
  /**
   * 주어진 문자열 (생년월일)에 하이픈(-)을 추가하여 'YYYY-MM-DD' 형식으로 변환합니다.
   *
   * 이 함수는 주어진 문자열이 'YYYYMMDD' 형식에 맞을 경우에만 하이픈을 추가하여 반환합니다.
   * 만약 문자열이 올바른 형식이 아니라면 원래 문자열을 그대로 반환합니다.
   *
   * @param {string} value - 생년월일 문자열
   * @return {string} 'YYYY-MM-DD' 형식으로 변환된 생년월일 문자열
   *
   * @example
   * // 문자열이 '20000128'인 경우 '2000-01-28'을 반환합니다.
   * birthdateHyphenText('20000128');
   */
  birthdateHyphenText,
  /**
   * 주어진 문자열의 UTF-8 인코딩 바이트 크기를 계산합니다.
   *
   * 이 함수는 주어진 문자열을 UTF-8로 인코딩하여 바이트 배열의 길이를 반환합니다.
   *
   * @param {string} str - 바이트 크기를 계산할 문자열.
   * @returns {number} 주어진 문자열의 총 바이트 크기.
   *
   * @example
   * // 'Hello'의 바이트 크기를 계산하여 5를 반환합니다.
   * calculateByteSize('Hello');
   *
   * @example
   * // '안녕하세요'의 바이트 크기를 계산하여 15를 반환합니다.
   * calculateByteSize('안녕하세요');
   *
   * @example
   * // 'Hello 🌍'의 바이트 크기를 계산하여 10을 반환합니다.
   * calculateByteSize('Hello 🌍');
   *
   * @example
   * // 빈 문자열의 바이트 크기를 계산하여 0을 반환합니다.
   * calculateByteSize('');
   */
  calculateByteSize,
  /**
   * 문자열 파일 크기를 확인하여 최대 크기(MB 단위)를 초과하지 않는지 여부를 반환합니다.
   *
   * 이 함수는 주어진 문자열을 Blob 객체로 변환하여 바이트 크기를 계산하고,
   * 해당 크기가 지정된 최대 크기(MB 단위)를 초과하는지 확인합니다.
   *
   * @param {string} stringFile - 크기를 확인할 문자열 파일.
   * @param {number} maxSizeMB - 최대 허용 크기(MB 단위).
   * @returns {boolean} 문자열 파일 크기가 최대 크기 이하일 경우 `true`, 초과하면 `false`.
   *
   * @example
   * // 문자열 크기가 1MB를 넘지 않는 경우 `true` 반환
   * checkStringFileSize('sample string', 1);
   *
   * @example
   * // 문자열 크기가 0.5MB보다 큰 경우 `false` 반환
   * checkStringFileSize('very large string...', 0.5);
   */
  checkStringFileSize,
};

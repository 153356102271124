export const Color = {
  // Red
  red50: '#fde6e6',
  red100: '#f8b0b0',
  red200: '#f58a8a',
  red300: '#f05454',
  red400: '#ed3333',
  red500: '#e90000',
  red600: '#d40000',
  red700: '#a50000',
  red800: '#800000',
  red900: '#620000',

  // Blue
  blue50: '#e6f2ff',
  blue100: '#b0d6ff',
  blue200: '#8ac2ff',
  blue300: '#54a6ff',
  blue400: '#3395ff',
  blue500: '#007aff',
  blue600: '#006fe8',
  blue700: '#0057b5',
  blue800: '#00438c',
  blue900: '#00336b',

  // White
  white50: '#ffffff',
  white100: '#f7f7f7',
  white200: '#f4f4f4',
  white300: '#eeeeee',
  white400: '#ebebeb',
  white500: '#e6e6e6',
  white600: '#d1d1d1',
  white700: '#a3a3a3',
  white800: '#7f7f7f',
  white900: '#616161',

  // Black
  black100: '#999999',
  black200: '#888888',
  black300: '#777777',
  black400: '#666666',
  black500: '#555555',
  black700: '#333333',
  black600: '#444444',
  black800: '#222222',
  black900: '#111111',

  // Sub Colors
  blueLight: '#f2f6fb',
  purple100: '#EBE0FC',
  purple200: '#D9C2FF',
  purple500: '#7536D6',
  purple600: '#5B19C0',
  green100: '#DBF0B0',
  green200: '#C0E080',
  green300: '#9ED039',
  green400: '#6DAC1B',
  green500: '#3C822B',
  yellow500: '#FFD600',

  // Table
  table: '#F1F3F9',
  'line-table': '#C5CDE5',

  // Others
  disabled: '#D9D9D9',
  black: '#000000',
  transparent: 'transparent',
} as const;

import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useRef, useState } from 'react';
import LocalOverlayPage from './OverlayPage';
import { overlayPageService } from './OverlayPage.service';
import type { OverlayPageListProps } from './OverlayPage.type';

export default function OverlayPageManager() {
  const [overlayPages, setOverlayPages] = useState<OverlayPageListProps[]>([]);

  const handleIsShowOverlayPageRef = useRef(
    (_: CustomEvent<{ id?: string; resolve: (result: boolean) => void }>) => {},
  );

  useEffect(() => {
    handleIsShowOverlayPageRef.current = (
      event: CustomEvent<{ id?: string; resolve: (result: boolean) => void }>,
    ) => {
      const { id, resolve } = event.detail;
      const isVisible = id
        ? overlayPages.some((page) => page.id === id && page.show)
        : overlayPages.length > 0;
      resolve(isVisible);
    };
  }, [overlayPages]);

  useEffect(() => {
    const handlePush = (event: CustomEvent<OverlayPageListProps>) => {
      setOverlayPages((prev) => [...prev, event.detail]);
    };

    const handleReplace = (e: CustomEvent<OverlayPageListProps>) => {
      setOverlayPages((prev) => {
        const index = prev.findIndex((page) => page.id === e.detail.id);
        if (index === -1) return [...prev, e.detail];

        const newPages = [...prev];
        newPages[index] = e.detail;
        return newPages;
      });
    };

    const handlePop = () => {
      setOverlayPages((prev) => prev.slice(0, -1));
    };

    const handlePopById = (event: CustomEvent<string>) => {
      setOverlayPages((prev) => prev.filter((overlayPage) => overlayPage.id !== event.detail));
    };

    const handlePopAll = () => {
      setOverlayPages([]);
    };

    const handleIsShowOverlayPage = (event: Event) =>
      handleIsShowOverlayPageRef.current(event as CustomEvent);

    document.addEventListener('pushOverlayPage', handlePush as EventListener);
    document.addEventListener('replaceOverlayPage', handleReplace as EventListener);
    document.addEventListener('popOverlayPage', handlePop);
    document.addEventListener('popOverlayPageById', handlePopById as EventListener);
    document.addEventListener('popAllOverlayPages', handlePopAll);
    document.addEventListener('isShowPopOverlayPage', handleIsShowOverlayPage as EventListener);

    return () => {
      document.removeEventListener('pushOverlayPage', handlePush as EventListener);
      document.removeEventListener('replaceOverlayPage', handleReplace as EventListener);
      document.removeEventListener('popOverlayPage', handlePop);
      document.removeEventListener('popOverlayPageById', handlePopById as EventListener);
      document.removeEventListener('popAllOverlayPages', handlePopAll);
      document.removeEventListener(
        'isShowPopOverlayPage',
        handleIsShowOverlayPage as EventListener,
      );
    };
  }, []);

  return (
    <Portal>
      {overlayPages.map((overlayPage) => (
        <LocalOverlayPage
          key={overlayPage.id}
          id={overlayPage.id}
          show={overlayPage.show}
          className={overlayPage.className}
          position={overlayPage.position}
          onClose={() => {
            overlayPage.id
              ? overlayPageService.popById(overlayPage.id)
              : overlayPageService.popAll();
            overlayPage.onClose?.();
          }}>
          {overlayPage.children}
        </LocalOverlayPage>
      ))}
    </Portal>
  );
}

import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoWhiteSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 24"
    {...props}
  >
    <path
      fill="#fff"
      d="m17.879.527-11.51 4.16c-.67.242-1.217.98-1.217 1.65v1.862h2.94v-2.92h2.911V8.2h2.94v2.89h-2.94v2.92h-2.91v-2.92h-2.94v12.02c0 .667.545 1.012 1.217.768l11.509-4.16c.671-.242 1.217-.98 1.217-1.65V1.295c0-.667-.546-1.012-1.217-.768"
      opacity={0.5}
    />
    <path
      fill="#fff"
      d="M17.879 19.236H5.153V6.334c0-.667.545-1.405 1.217-1.65L17.879.524c.671-.241 1.217.101 1.217.768v-.04c0-.666-.546-1.208-1.217-1.208H1.217C.546.044 0 .586 0 1.252v16.773c0 .666.546 1.208 1.217 1.208H17.88z"
    />
    <path
      fill="#fff"
      d="M19.096 1.295c0-.667-.546-1.012-1.217-.768l-11.51 4.16c-.67.242-1.216.98-1.216 1.65v12.902h12.726a1.217 1.217 0 0 0 1.217-1.21V1.296m-5.155 9.791H11v2.92H8.09v-2.92H5.15v-2.89h2.94v-2.92H11v2.92h2.94z"
      opacity={0.5}
    />
  </svg>
);
export default SvgLogoWhiteSymbol;

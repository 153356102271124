import { useClickOutside } from '@shared-hooks/use-click-outside';
import { CustomCaption } from 'afterdoc-design-system/components/Atoms/Calendar/components/CustomCaption';
import { useHolidays } from 'afterdoc-design-system/components/Atoms/Calendar/hooks/use-fetch-holidays';
import { ko } from 'date-fns/locale';
import dayjs from 'dayjs';
import { type RefObject, useEffect, useRef, useState } from 'react';
import { type DayPickerSingleProps, DayPicker as ReactDayPicker } from 'react-day-picker';
import { v4 as uuidv4 } from 'uuid';
import './styles/DayPicker.scss';

export interface DayPickerProps
  extends Omit<DayPickerSingleProps, 'mode' | 'selected' | 'onSelect'> {
  id?: string;
  selectedDate?: Date;
  onChangeDate: (date?: Date) => void;
  handleToggle?: () => void;
  hasError?: boolean;
  errorText?: string;
  monthRange?: [number, number];
  yearRange?: [number, number];
  ignoreRefs?: RefObject<HTMLElement>[];
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  onEscKeyDown?: () => void;
}

export default function DayPicker({
  id,
  selectedDate,
  onChangeDate,
  handleToggle,
  hasError,
  errorText,
  monthRange,
  yearRange = [1900, dayjs().year()],
  ignoreRefs,
  disableBeforeDate,
  disableAfterDate,
  onEscKeyDown,
  ...props
}: DayPickerProps) {
  const initialDateRef = useRef(selectedDate);
  const dayPickerRef = useRef<HTMLDivElement>(null);

  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [selectedMonth, setSelectedMonth] = useState(dayjs(selectedDate).month());
  const [selectedYear, setSelectedYear] = useState(dayjs(selectedDate).year());

  const handleYearChange = (newYear: number) => {
    const initialYear = dayjs(initialDateRef.current).year();
    if (newYear !== initialYear) {
      setSelectedDay(undefined);
    } else {
      setSelectedDay(selectedDate);
    }
    setSelectedYear(newYear);
  };

  const handleMonthChange = (newMonth: number) => {
    const initialDate = dayjs(initialDateRef.current);
    if (dayjs(selectedDate).year() === initialDate.year() && newMonth === initialDate.month() + 1) {
      setSelectedDay(selectedDate);
    } else {
      setSelectedDay(undefined);
    }
    setSelectedMonth(newMonth - 1);
  };

  const { holidays } = useHolidays(selectedYear, selectedMonth + 1);

  const modifiers = {
    holiday: holidays,
  };

  useEffect(() => {
    setSelectedDay(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onEscKeyDown) {
        event.stopImmediatePropagation();
        onEscKeyDown();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscKeyDown]);

  if (handleToggle) {
    useClickOutside({
      id: id ?? uuidv4(),
      ref: dayPickerRef,
      contentRef: dayPickerRef,
      onClose: handleToggle,
      ignoreRefs: ignoreRefs ?? [],
    });
  }

  return (
    <div className='DayPicker' ref={dayPickerRef}>
      <ReactDayPicker
        showOutsideDays
        locale={ko}
        mode='single'
        selected={selectedDay}
        onDayClick={onChangeDate}
        month={new Date(selectedYear, selectedMonth)}
        components={{
          Caption: () => (
            <CustomCaption
              hasError={hasError}
              errorText={errorText}
              currentYear={selectedYear}
              currentMonth={selectedMonth}
              monthRange={monthRange}
              yearRange={yearRange}
              onChangeYear={handleYearChange}
              onChangeMonth={handleMonthChange}
            />
          ),
        }}
        modifiers={modifiers}
        modifiersClassNames={{
          holiday: 'rdp-day_holiday',
          disabled: 'rdp-day_disabled',
        }}
        disabled={[
          disableAfterDate
            ? {
                after: disableAfterDate,
              }
            : false,
          disableBeforeDate
            ? {
                before: disableBeforeDate,
              }
            : false,
        ]}
        {...props}
      />
    </div>
  );
}

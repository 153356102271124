import MonthCaption from 'afterdoc-design-system/components/Atoms/Calendar/components/CustomCaption/components/MonthCaption';
import YearCaption from 'afterdoc-design-system/components/Atoms/Calendar/components/CustomCaption/components/YearCaption';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';

interface CustomCaptionProps {
  currentYear?: number;
  currentMonth?: number;
  monthRange?: [number, number];
  yearRange?: [number, number];
  hasError?: boolean;
  errorText?: string;
  onChangeYear?: (year: number) => void;
  onChangeMonth?: (month: number) => void;
}
export function CustomCaption({
  currentYear,
  currentMonth,
  monthRange = [1, 12],
  yearRange = [1900, dayjs().year()],
  hasError,
  errorText,
  onChangeYear,
  onChangeMonth,
}: CustomCaptionProps) {
  const customFocusScrollHandler = (focusedIndex: number) => {
    return focusedIndex - 2;
  };

  const customSelectedScrollHandler = (selectedIndex: number) => {
    return selectedIndex - 2;
  };

  return (
    <div className='dropdown-container'>
      <div className='year-month-dropdown-wrapper'>
        <YearCaption
          currentYear={currentYear ?? dayjs().year()}
          onChangeYear={onChangeYear}
          yearRange={yearRange}
          customFocusScrollHandler={customFocusScrollHandler}
          customSelectedScrollHandler={customSelectedScrollHandler}
        />
        <MonthCaption
          currentMonth={currentMonth ?? dayjs().month() + 1}
          onChangeMonth={onChangeMonth}
          monthRange={monthRange}
          customFocusScrollHandler={customFocusScrollHandler}
          customSelectedScrollHandler={customSelectedScrollHandler}
        />
      </div>
      {hasError && (
        <div className='flex items-center gap-4'>
          <Icon name='info-circle-outline' color='red500' size={16} />
          <div className='whitespace-nowrap text-Body11 text-red500'>
            {errorText ?? '날짜 형식에 맞게 입력해 주세요.'}
          </div>
        </div>
      )}
    </div>
  );
}

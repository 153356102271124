interface UseDropdownKeyboardEventProps {
  keycode: string | null;
  listEl: HTMLDivElement;
  onEnter: (selectedValue: string) => void;
  onEsc: () => void;
  focusedIndex?: number;
  setFocusedIndex: (index?: number) => void;
}
export const useDropdownKeyboardEvent = ({
  keycode,
  listEl,
  onEnter,
  onEsc,
  focusedIndex,
  setFocusedIndex,
}: UseDropdownKeyboardEventProps) => {
  const liNodes = Array.from(
    listEl.querySelectorAll('div[aria-labelledby="tag-option"]'),
  ) as HTMLElement[];

  if (keycode === 'ArrowDown') {
    if (liNodes.length === 0) return;

    if (focusedIndex === undefined || focusedIndex === null) {
      setFocusedIndex(0);
      liNodes[0]?.focus();
    } else if (focusedIndex < liNodes.length - 1) {
      setFocusedIndex(focusedIndex + 1);
      liNodes[focusedIndex + 1]?.focus();
    }
  }

  if (keycode === 'ArrowUp') {
    if (liNodes.length === 0) return; // `filteredTags`가 없을 때 `ArrowUp` 이벤트 무시

    if (focusedIndex === undefined || focusedIndex === null) {
      setFocusedIndex(liNodes.length - 1);
      liNodes[liNodes.length - 1]?.focus();
    } else if (focusedIndex > 0) {
      setFocusedIndex(focusedIndex - 1);
      liNodes[focusedIndex - 1]?.focus();
    }
  }

  if (keycode === 'Enter') {
    if (focusedIndex === undefined || focusedIndex === null) {
      const focusedElement = liNodes[0];
      if (focusedElement) {
        const selectedId = focusedElement.id;
        if (selectedId) {
          onEnter(selectedId);
        }
      }
    } else {
      const focusedElement = liNodes[focusedIndex];
      if (focusedElement) {
        const selectedId = focusedElement.id;
        if (selectedId) {
          onEnter(selectedId);
        }
      }
    }
  }

  if (keycode === 'Escape') {
    onEsc();
  }
};

import { FontWeight } from './font-weight';
import { LineHeight } from './line-height';

export const Text = {
  '.text-Header16': {
    fontSize: '16px',
    fontWeight: FontWeight.bold,
    lineHeight: LineHeight.h22,
  },
  '.text-Header14': {
    fontSize: '14px',
    fontWeight: FontWeight.bold,
    lineHeight: LineHeight.h18,
  },
  '.text-Header12': {
    fontSize: '12px',
    fontWeight: FontWeight.bold,
    lineHeight: LineHeight.h16,
  },
  '.text-Body14': {
    fontSize: '14px',
    lineHeight: LineHeight.h18,
  },
  '.text-Body13': {
    fontSize: '13px',
    fontWeight: FontWeight.medium,
    lineHeight: LineHeight.h18,
  },
  '.text-Body12': {
    fontSize: '12px',
    lineHeight: LineHeight.h18,
  },
  '.text-Body11': {
    fontSize: '11px',
    lineHeight: LineHeight.h16,
  },
  '.text-Body10': {
    fontSize: '10px',
    lineHeight: LineHeight.h15,
  },
  '.text-Body10Bold': {
    fontSize: '10px',
    fontWeight: FontWeight.bold,
    lineHeight: LineHeight.h15,
  },
  '.text-Caption9': {
    fontSize: '9px',
    lineHeight: LineHeight.h13,
  },
  '.text-Caption8': {
    fontSize: '8px',
    lineHeight: LineHeight.h12,
  },
};

import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dotsSpinnerAnimationData from 'afterdoc-design-system/assets/lotties/dots-spinner.json';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAnimationToggle } from 'afterdoc-design-system/hooks/use-animation-toggle';
import type { LottiePlayer } from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import TextButton from '../Button/TextButton';
import type { ToastProps } from './Toast.type';

export default function ToastComponent({
  text,
  leftIcon,
  type,
  rightButton,
  closeDelay,
  autoCloseTimestamp,
  style,
  id,
}: ToastProps) {
  const dissolveTimeout = useRef<ReturnType<typeof setTimeout>>();

  const { mounted, trigger, onShow, onHide } = useAnimationToggle({
    transitionDuration: closeDelay || 0 + 1000,
  });

  useEffect(() => {
    onShow();
  }, [onShow]);

  useEffect(() => {
    if (autoCloseTimestamp === -1) {
      onHide();
      clearTimeout(dissolveTimeout.current);
    }
  }, [autoCloseTimestamp, onHide]);

  useEffect(() => {
    if (closeDelay) {
      dissolveTimeout.current = setTimeout(onHide, closeDelay);
    }
    return () => clearTimeout(dissolveTimeout.current);
  }, [closeDelay, onHide]);

  if (!mounted) return null;

  return (
    mounted && (
      <div
        id={id}
        className='!bg-opacity-70 relative mx-auto my-0 inline-block w-fit min-w-[300px] whitespace-nowrap rounded-r10 bg-black900 px-20 py-10 font-bold backdrop-blur-[5px]'
        style={{
          ...style,
          opacity: trigger,
          transform: trigger ? undefined : 'translate(0,30px)',
        }}>
        <div className='flex w-full flex-row items-center justify-between'>
          <div className='flex-center gap-10'>
            {leftIcon && <Icon {...leftIcon} />}
            {type === 'loading' && <LottieLoading />}
            <div
              className={customTwMerge(
                'inline-block whitespace-break-spaces text-Body12 text-white50',
              )}>
              {text}
            </div>
          </div>
          {rightButton && (
            <TextButton
              onClick={rightButton.onClick}
              textColor='white50'
              className='font-bold text-Header12'>
              {rightButton.text}
            </TextButton>
          )}
        </div>
      </div>
    )
  );
}

const getLottiePath = () => {
  try {
    return dotsSpinnerAnimationData;
  } catch (_error) {
    console.error('Lottie file not found');
    return null;
  }
};

const LottieLoading = () => {
  const animationData = getLottiePath();

  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current && animationData) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => animation.destroy();
    }
  }, [lottie, animationData]);

  return <div ref={ref} className='h-20 w-20' />;
};

import { useEffect, useRef } from 'react';
import BaseLoading from '../BaseLoading';

export default function FullDimmedLoading() {
  const fullDimmedLoadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <>
      <div
        className='!bg-opacity-50 fixed top-0 left-0 z-[9998] flex-full-center select-none bg-white50'
        ref={fullDimmedLoadingRef}
      />
      <div className='fixed top-0 left-0 z-[9999] flex-full-center select-none'>
        <BaseLoading width={48} height={60} />
      </div>
    </>
  );
}

export const fullDimmedLoadingService = {
  on() {
    const customEvent = new CustomEvent('pushFullDimmedLoading');
    document.dispatchEvent(customEvent);
  },

  off() {
    const customEvent = new CustomEvent('popFullDimmedLoading');
    document.dispatchEvent(customEvent);
  },
};

import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { Status } from './ProgressBar';

export const RenderingProgressDownloadIcon = ({ status }: { status: Status }) => {
  switch (status) {
    case 'complete':
      return <Icon name='done' color='white50' />;
    case 'error':
      return <Icon name='warning' color='white50' />;
    case 'downloading':
      return <Icon name='tray-download' color='white50' />;
    default:
      return null;
  }
};

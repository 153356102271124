import { Icon as IconComponent, type Icon as MdiIcon } from '@mdi/react';
import { Color } from '@tailwind-base/styles/color';
import { type CustomIconType, iconsMapping } from 'afterdoc-design-system/assets/icon-mapping';
import {
  DEFAULT_ICON_SIZES,
  FIXED_ICON_COLORS,
  FIXED_ICON_SIZES,
} from 'afterdoc-design-system/components/Foundations/Icon/constants/icon';
import type React from 'react';
import { type ComponentProps, useEffect, useState } from 'react';
import { MDIconsPaths } from './MDIcons';

type MdiIconType = keyof typeof MDIconsPaths;

export type IconType = CustomIconType | MdiIconType;
export type IconColorType = keyof typeof Color;

type DefaultIconSizes = (typeof DEFAULT_ICON_SIZES)[number];
type FixedIconSizes = typeof FIXED_ICON_SIZES;
export type SpecificIconSizes<T> = T extends keyof FixedIconSizes
  ? FixedIconSizes[T][number]
  : DefaultIconSizes;

export type IconProps<T extends IconType = IconType> = {
  name: T;
  size?: SpecificIconSizes<T>;
  disabled?: boolean;
  color?: IconColorType;
  customColor?: string;
} & Omit<ComponentProps<typeof MdiIcon>, 'color' | 'path'>;

const isCustomIconType = (name: string): name is CustomIconType => {
  return Object.keys(iconsMapping).includes(name);
};

export default function Icon<T extends IconType>({
  name,
  size,
  color,
  disabled,
  customColor,
  ...props
}: IconProps<T>) {
  const [CustomIconComponent, setCustomIconComponent] = useState<React.ComponentType<{
    width: string;
    height: string;
    fill: string;
  }> | null>(null);

  useEffect(() => {
    if (isCustomIconType(name)) {
      iconsMapping[name]().then((module) => {
        setCustomIconComponent(() => module.default);
      });
    }
  }, [name]);

  const FIXED_ICON_SIZESList = FIXED_ICON_SIZES[name as keyof FixedIconSizes];
  const defaultSize = FIXED_ICON_SIZES[name as keyof FixedIconSizes]?.[0] ?? 24;

  const isValidSize = FIXED_ICON_SIZESList
    ? (FIXED_ICON_SIZESList as readonly number[]).includes(size as number)
    : DEFAULT_ICON_SIZES.includes(size as DefaultIconSizes);

  const iconSize = isValidSize ? size : defaultSize;
  const sizeNum = `${iconSize}px`;

  let iconFill: string;
  if (disabled) {
    iconFill = Color.disabled;
  } else if (customColor) {
    iconFill = customColor;
  } else if (FIXED_ICON_COLORS[name as IconType]) {
    iconFill = FIXED_ICON_COLORS[name as IconType] as string;
  } else if (color) {
    iconFill = Color[color];
  } else {
    iconFill = Color.black500;
  }

  if (CustomIconComponent) {
    return <CustomIconComponent width={sizeNum} height={sizeNum} fill={iconFill} {...props} />;
  }

  const iconPath = MDIconsPaths[name as MdiIconType];
  return iconPath ? (
    <IconComponent path={iconPath} size={sizeNum} color={iconFill} {...props} />
  ) : null;
}

export interface Rgb {
  r: number;
  g: number;
  b: number;
}

export interface Hsb {
  h: number;
  s: number;
  b: number;
}

export const convertRgbToHsb = ({ r, g, b }: Rgb): Hsb => {
  const rPrime = r / 255;
  const gPrime = g / 255;
  const bPrime = b / 255;

  const max = Math.max(rPrime, gPrime, bPrime);
  const min = Math.min(rPrime, gPrime, bPrime);
  const delta = max - min;

  let h = 0;
  if (delta !== 0) {
    if (max === rPrime) {
      h = ((gPrime - bPrime) / delta) % 6;
    } else if (max === gPrime) {
      h = (bPrime - rPrime) / delta + 2;
    } else {
      h = (rPrime - gPrime) / delta + 4;
    }
    h *= 60;
    if (h < 0) h += 360;
  }

  const s = max === 0 ? 0 : delta / max;
  const v = max;

  return { h, s: s * 100, b: v * 100 };
};

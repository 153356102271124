import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useCallback, useEffect, useState } from 'react';
import Alert from './Alert';
import { alertService } from './Alert.service';
import type { AlertListProps } from './Alert.type';

export default function AlertManager() {
  const [alerts, setAlerts] = useState<AlertListProps[]>([]);

  const handlePush = useCallback((event: CustomEvent<AlertListProps>) => {
    setAlerts((prev) => [...prev, event.detail]);
  }, []);

  const handlePop = useCallback(() => {
    setAlerts((prev) => {
      const newAlerts = [...prev];
      newAlerts.pop();
      return newAlerts;
    });
  }, []);

  const handlePopById = useCallback((event: CustomEvent<string>) => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== event.detail));
  }, []);

  const handlePopAll = useCallback(() => {
    setAlerts([]);
  }, []);

  // 이벤트 리스너 등록만 관리
  useEffect(() => {
    document.addEventListener('pushAlert', handlePush as EventListener);
    document.addEventListener('popAlert', handlePop);
    document.addEventListener('popAlertById', handlePopById as EventListener);
    document.addEventListener('popAllAlerts', handlePopAll);

    return () => {
      document.removeEventListener('pushAlert', handlePush as EventListener);
      document.removeEventListener('popAlert', handlePop);
      document.removeEventListener('popAlertById', handlePopById as EventListener);
      document.removeEventListener('popAllAlerts', handlePopAll);
    };
  }, [handlePop, handlePopAll, handlePopById, handlePush]);

  return (
    <Portal>
      <div className='absolute top-0 right-[10px] z-[10000] mt-10 flex max-h-[500px] flex-col gap-10'>
        {alerts.slice(0, 6).map((alert) => (
          <Alert
            key={alert.id}
            {...alert}
            onClose={() => {
              if (alert.id) {
                alertService.popById(alert.id);
              } else {
                alertService.popAll();
              }
              alert.onClose?.();
            }}
          />
        ))}
        {alerts.length >= 6 && (
          <div
            className='pointer-events-none absolute right-0 bottom-0 left-0 h-[100px] cursor-pointer bg-gradient-to-t from-white/20 via-white/10 to-transparent'
            style={{
              backdropFilter: 'blur(1px)',
              WebkitBackdropFilter: 'blur(1px)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (alerts[alerts.length - 1]?.id) {
                alertService.popById(alerts[alerts.length - 1].id as string);
              } else {
                alertService.popAll();
              }
              alerts[alerts.length - 1].onClose?.();
            }}
          />
        )}
      </div>
    </Portal>
  );
}

import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon, { type IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { type MouseEventHandler, forwardRef } from 'react';

export interface IconButtonProps {
  icon: IconProps['name'];
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  size?: IconProps['size'];
  color?: IconProps['color'];
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { onClick, icon, color, size, disabled, type, className }: IconButtonProps,
  ref,
) {
  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={onClick}
      type={type ?? 'button'}
      className={customTwMerge('select-none', className)}>
      <Icon name={icon} size={size} color={disabled ? 'white700' : color} />
    </button>
  );
});

export default IconButton;

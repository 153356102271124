import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { CSSProperties } from 'react';

type DividerType = 'line' | 'plane';
type DividerDirection = 'horizontal' | 'vertical';

const TYPE: {
  [key in DividerType]: DividerType;
} = {
  line: 'line',
  plane: 'plane',
};

const DIRECTION: {
  [key in DividerDirection]: DividerDirection;
} = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const DIVIDER_TYPE = Object.keys(TYPE) as DividerType[];
export const DIVIDER_DIRECTION = Object.keys(DIRECTION) as DividerDirection[];

export interface DividerProps {
  margin?: number;
  type: DividerType;
  direction?: DividerDirection;
  width?: number;
  height?: number;
  className?: string;
}

const baseStyles = {
  line: {
    horizontalStyle: 'w-full',
    verticalStyle: 'h-full',
  },
  plane: {
    horizontalStyle: 'h-10 my-1',
    verticalStyle: 'h-full w-10 mx-1',
  },
};

const getDefaultDimension = (
  type: 'line' | 'plane',
  direction: 'horizontal' | 'vertical',
  dimension: 'width' | 'height',
) => {
  const defaultDimensions = {
    line: {
      horizontal: { width: '100%', height: '1px' },
      vertical: { width: '1px', height: '100%' },
    },
    plane: {
      horizontal: { width: '100%', height: '10px' },
      vertical: { width: '10px', height: '100%' },
    },
  };

  return defaultDimensions[type][direction][dimension];
};

export default function Divider({
  margin = 0,
  type = 'line',
  direction = 'horizontal',
  width,
  height,
  className,
}: DividerProps) {
  const customStyles: CSSProperties = {
    width:
      width !== undefined
        ? `${width}px`
        : direction === 'horizontal'
          ? `calc(100% - ${margin ? margin * 2 : 0}px)`
          : getDefaultDimension(type, direction, 'width'),
    height: height !== undefined ? `${height}px` : getDefaultDimension(type, direction, 'height'),
    ...(margin !== undefined &&
      direction === 'horizontal' && { marginLeft: `${margin}px`, marginRight: `${margin}px` }),
    ...(margin !== undefined &&
      direction === 'vertical' && { marginTop: `${margin}px`, marginBottom: `${margin}px` }),
  };

  const combinedStyles = customTwMerge(
    'bg-white600',
    baseStyles[type][`${direction}Style`],
    className,
  );

  return <div className={combinedStyles} style={customStyles} />;
}

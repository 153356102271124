import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import {
  RadioGroupContext,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  type ChangeEvent,
  type InputHTMLAttributes,
  type LabelHTMLAttributes,
  type ReactNode,
  forwardRef,
  useContext,
  useId,
  useMemo,
} from 'react';

export type BaseAttributes = Omit<InputHTMLAttributes<HTMLInputElement>, 'checked'>;

export interface RadioProps extends BaseAttributes {
  checked?: boolean;
  defaultChecked?: boolean;
  label?: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  wrapperClassName?: string;
}

export interface RadioLabelProps {
  label: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioLabel = ({ label, labelProps, id, checked, disabled, onChange }: RadioLabelProps) => {
  const uid = useId();

  return (
    <label
      {...labelProps}
      htmlFor={id || uid}
      className={customTwMerge(
        'text-Body12',
        checked ? 'text-black500' : disabled ? 'text-disabled' : 'text-black200',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        labelProps?.className,
      )}
      aria-disabled={disabled}
      onClick={() => {
        if (!disabled && onChange) {
          onChange({ target: { checked: true } } as ChangeEvent<HTMLInputElement>);
        }
      }}>
      {label}
    </label>
  );
};

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      label,
      labelProps,
      id,
      className,
      wrapperClassName,
      checked,
      defaultChecked,
      disabled,
      name,
      value,
      onChange,
      ...radioProps
    },
    ref,
  ) => {
    const uid = useId();
    const groupContext = useContext(RadioGroupContext);

    const isControlled = useMemo(() => checked !== undefined, [checked]);

    const isChecked = groupContext
      ? groupContext.value === value
      : checked !== undefined
        ? checked
        : defaultChecked;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (groupContext) {
        groupContext.onChange(value as ValueType);
      } else {
        onChange?.(e);
      }
    };

    return (
      <div
        className={customTwMerge(
          label && 'flex min-h-30 select-none items-center gap-10 py-3',
          wrapperClassName,
        )}>
        <div
          className={customTwMerge(
            'relative inline-block h-24 w-24 shrink-0 text-center',
            className,
          )}>
          <input
            {...radioProps}
            ref={ref}
            id={id || uid}
            className='absolute left-0 h-0 w-0 opacity-0'
            type='radio'
            name={groupContext ? groupContext.name : name}
            checked={isControlled ? isChecked : undefined}
            defaultChecked={isControlled ? undefined : defaultChecked}
            disabled={disabled}
            onChange={handleChange}
            value={value}
          />
          <label
            className={customTwMerge(
              'flex-full-center',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
            htmlFor={id || uid}>
            <Icon
              name={isChecked ? 'radio-button-checked' : 'radio-button-unchecked'}
              color={isChecked ? 'blue500' : disabled ? 'disabled' : 'black200'}
              size={24}
            />
          </label>
        </div>
        {label && (
          <RadioLabel
            label={label}
            labelProps={labelProps}
            id={id}
            checked={isChecked}
            disabled={disabled}
            onChange={handleChange}
          />
        )}
      </div>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;

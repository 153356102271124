import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { CSSProperties, ReactNode } from 'react';

type EnableBadgeColor = 'blue' | 'red';
type EnableBadgeVertical = 'top' | 'bottom';
type EnableBadgeHorizontal = 'left' | 'right';

export interface BadgeProps {
  children: ReactNode;
  color?: EnableBadgeColor;
  vertical?: EnableBadgeVertical;
  horizontal?: EnableBadgeHorizontal;
  verticalValue?: number;
  horizontalValue?: number;
  wrapperClassName?: string;
  className?: string;
  style?: CSSProperties;
  isBadge?: boolean;
}

const BADGE_COLORS: {
  [key in EnableBadgeColor]: EnableBadgeColor;
} = {
  blue: 'blue',
  red: 'red',
};

const BADGE_VERTICAL: {
  [key in EnableBadgeVertical]: EnableBadgeVertical;
} = {
  top: 'top',
  bottom: 'bottom',
};

const BADGE_HORIZONTAL: {
  [key in EnableBadgeHorizontal]: EnableBadgeHorizontal;
} = {
  left: 'left',
  right: 'right',
};

export const BADGE_COLORS_ARR = Object.keys(BADGE_COLORS) as EnableBadgeColor[];
export const BADGE_VERTICAL_ARR = Object.keys(BADGE_VERTICAL) as EnableBadgeVertical[];
export const BADGE_HORIZONTAL_ARR = Object.keys(BADGE_HORIZONTAL) as EnableBadgeHorizontal[];

export default function Badge({
  children,
  isBadge = true,
  color = 'red',
  vertical = 'top',
  horizontal = 'right',
  verticalValue,
  horizontalValue,
  wrapperClassName,
  className,
  style,
}: BadgeProps) {
  if (!isBadge) return <>{children}</>;

  const positionStyle = {
    top: vertical === 'top' ? `${verticalValue ?? 0}px` : undefined,
    bottom: vertical === 'bottom' ? `${verticalValue ?? 0}px` : undefined,
    left: horizontal === 'left' ? `${horizontalValue ?? 0}px` : undefined,
    right: horizontal === 'right' ? `${horizontalValue ?? 0}px` : undefined,
    transform: `translate(${horizontal === 'right' ? '50%' : '-50%'}, ${vertical === 'top' ? '-50%' : '50%'})`,
  };

  return (
    <div className={customTwMerge('relative inline-block select-none', wrapperClassName)}>
      {children}
      <span
        className={customTwMerge(
          'absolute h-6 w-6 rounded-full',
          color === 'red' ? 'bg-red500' : 'bg-blue500',
          className,
        )}
        style={{
          ...positionStyle,
          ...style,
        }}
      />
    </div>
  );
}

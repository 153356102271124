import { type CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useCheckTargetOverflowScreen } from './use-check-target-overflow-screen';
import type { PageOffset } from './use-pointer-event';

interface UsePositioningByCursorProps {
  cursorPosition: PageOffset | null;
  contentId?: string;
}

export const usePositioningByCursor = ({
  cursorPosition,
  contentId,
}: UsePositioningByCursorProps) => {
  const [style, setStyle] = useState<CSSProperties>();
  const target = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!contentId) return;
    const t = document.getElementById(contentId);
    target.current = t;
  }, [contentId]);

  const setPositionByCursor = useCallback(() => {
    if (!cursorPosition || !target.current) return;

    const { innerWidth } = window;
    const { clientX, clientY } = cursorPosition;
    const { clientWidth, clientHeight } = target.current;
    const { isWidthOverflow, isHeightOverflow, OFFSET_GAP } = useCheckTargetOverflowScreen({
      clientX,
      clientY,
      clientWidth,
      clientHeight,
    });
    const correctionLeft = isWidthOverflow
      ? innerWidth - clientWidth - OFFSET_GAP
      : clientX + OFFSET_GAP;
    const correctionTop = isHeightOverflow
      ? clientY - clientHeight - OFFSET_GAP
      : clientY + OFFSET_GAP;

    setStyle({
      position: 'fixed',
      opacity: 100,
      left: `${correctionLeft}px`,
      top: `${correctionTop}px`,
    });
  }, [cursorPosition, target]);

  useEffect(() => {
    setPositionByCursor();
  }, [setPositionByCursor]);

  return { style };
};

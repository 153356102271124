import type { ContextMenuListProps } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';

export interface UseHandleContextMenuListProps {
  contextMenuList: ContextMenuListProps[];
  setContextMenuList: React.Dispatch<React.SetStateAction<ContextMenuListProps[]>>;
}

export const useHandleContextMenuList = ({
  contextMenuList,
  setContextMenuList,
}: UseHandleContextMenuListProps) => {
  const popLatestContextMenu = () => {
    if (!contextMenuList.length) return;

    const foreFrontContextMenuId = [...contextMenuList].reverse()[0].id;

    foreFrontContextMenuId && popContextMenuById(foreFrontContextMenuId);
  };

  const popContextMenuById = (id: string) => {
    if (!contextMenuList.length) return;

    setContextMenuList((prev) => prev.filter((contextMenu) => contextMenu.id !== id));
  };

  const popAll = () => setContextMenuList([]);

  return { popLatestContextMenu, popContextMenuById, popAll };
};

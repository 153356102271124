export const LineHeight = {
  h22: '22px',
  h18: '18px',
  h16: '16px',
  h15: '15px',
  h13: '13px',
  h12: '12px',
  h26: '26px',
  h24: '24px',
} as const;

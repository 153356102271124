import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useEffect, useState } from 'react';

export interface PaginationProps {
  totalPages?: number;
  defaultPage?: number;
  selectedPage?: number;
  onPageChange?: (page: number) => void;
  className?: string;
}

export default function Pagination({
  totalPages = 5,
  defaultPage = 1,
  selectedPage,
  onPageChange,
  className,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const pagesPerGroup = 5;

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const startPage = Math.floor((currentPage - 1) / pagesPerGroup) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  useEffect(() => {
    if (!selectedPage) return;
    handlePageChange(selectedPage);
  }, [selectedPage]);

  return (
    <div
      className={customTwMerge(
        'flex h-full w-full items-center justify-center gap-10 py-10',
        className,
      )}>
      <IconButton
        icon='left-last-page'
        size={16}
        color='black500'
        className='rounded-r6 border border-white400 p-4'
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
      <IconButton
        icon='chevron-left'
        size={16}
        color='black500'
        className='rounded-r6 border border-white400 p-4'
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <div className='flex items-center gap-10'>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const pageNumber = startPage + index;
          return (
            <button
              type='button'
              key={pageNumber}
              className={customTwMerge(
                'px-10 text-center',
                currentPage === pageNumber
                  ? 'text-Header12 text-black700'
                  : 'text-Body12 text-black200',
              )}
              onClick={() => handlePageChange(pageNumber)}>
              {pageNumber}
            </button>
          );
        })}
      </div>
      <IconButton
        icon='chevron-right'
        size={16}
        color='black500'
        className='rounded-r6 border border-white400 p-4'
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <IconButton
        icon='right-last-page'
        size={16}
        color='black500'
        className='rounded-r6 border border-white400 p-4'
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    </div>
  );
}

import { customEventService } from 'afterdoc-design-system/hooks/use-custom-event-listener';
import { uniqueId } from 'lodash-es';
import type { ToastEventListenerType, ToastProps } from './Toast.type';

const NewToast = ({
  text,
  type,
  leftIcon,
  rightButton,
  id,
  autoClose = true,
}: ToastProps & {
  autoClose?: boolean;
}) => {
  const uid = id ?? uniqueId('toast-');

  const autoCloseTimestamp = autoClose ? Date.now() + 1500 : undefined;

  return {
    text,
    type,
    leftIcon,
    rightButton,
    id: uid,
    autoCloseTimestamp,
    closeDelay: autoClose ? 1500 : undefined,
  };
};

export const toastService = {
  defaultMsg(defaultMsg: {
    text: ToastProps['text'];
    rightButton?: ToastProps['rightButton'];
    type: ToastProps['type'];
    id?: ToastProps['id'];
  }) {
    const uid = defaultMsg?.id ?? uniqueId('toast-');
    const customEvent = new CustomEvent('defaultMsg', {
      detail: NewToast({
        ...defaultMsg,
        id: uid,
        type: 'default',
        autoClose: true,
      }),
    });
    customEventService.dispatch<ToastEventListenerType, ToastProps>('[toast] show DefaultMsg', {
      detail: NewToast({
        ...defaultMsg,
        id: uid,
        type: 'default',
        autoClose: true,
      }),
    });
    document.dispatchEvent(customEvent);

    return uid;
  },
  customMsg(customMsg: {
    text: ToastProps['text'];
    leftIcon?: ToastProps['leftIcon'];
    rightButton?: ToastProps['rightButton'];
    id?: ToastProps['id'];
  }) {
    const uid = customMsg?.id ?? uniqueId('toast-');
    const customEvent = new CustomEvent('customMsg', {
      detail: NewToast({
        ...customMsg,
        id: uid,
        type: 'custom',
        autoClose: true,
      }),
    });
    customEventService.dispatch<ToastEventListenerType, ToastProps>('[toast] show CustomMsg', {
      detail: NewToast({
        ...customMsg,
        id: uid,
        type: 'custom',
        autoClose: true,
      }),
    });
    document.dispatchEvent(customEvent);

    return uid;
  },
  errorMsg(errorMsg: {
    text: ToastProps['text'];
    rightButton?: ToastProps['rightButton'];
    id?: ToastProps['id'];
  }) {
    const uid = errorMsg?.id ?? uniqueId('toast-');
    const customEvent = new CustomEvent('errorMsg', {
      detail: NewToast({
        ...errorMsg,
        id: uid,
        type: 'error',
        leftIcon: {
          name: 'warning',
          color: 'yellow500',
          size: 20,
        },
        autoClose: true,
      }),
    });
    customEventService.dispatch<ToastEventListenerType, ToastProps>('[toast] show ErrorMsg', {
      detail: NewToast({
        ...errorMsg,
        id: uid,
        type: 'error',
        leftIcon: {
          name: 'warning',
          color: 'yellow500',
          size: 20,
        },
        autoClose: true,
      }),
    });
    document.dispatchEvent(customEvent);

    return uid;
  },
  successMsg(successMsg: {
    text: ToastProps['text'];
    rightButton?: ToastProps['rightButton'];
    id?: ToastProps['id'];
  }) {
    const uid = successMsg.id ?? uniqueId('toast-');
    const customEvent = new CustomEvent('successMsg', {
      detail: NewToast({
        ...successMsg,
        id: uid,
        type: 'success',
        leftIcon: {
          name: 'done',
          color: 'green300',
          size: 20,
        },
        autoClose: true,
      }),
    });
    customEventService.dispatch<ToastEventListenerType, ToastProps>('[toast] show SuccessMsg', {
      detail: NewToast({
        ...successMsg,
        id: uid,
        type: 'success',
        leftIcon: {
          name: 'done',
          color: 'green300',
          size: 20,
        },
        autoClose: true,
      }),
    });
    document.dispatchEvent(customEvent);

    return uid;
  },
  loadingMsg(loadingMsg: {
    text: ToastProps['text'];
    rightButton?: ToastProps['rightButton'];
    id?: ToastProps['id'];
  }) {
    const uid = loadingMsg.id ?? uniqueId('toast-');
    const customEvent = new CustomEvent('loadingMsg', {
      detail: NewToast({
        ...loadingMsg,
        id: uid,
        type: 'loading',
        autoClose: false,
      }),
    });
    customEventService.dispatch<ToastEventListenerType, ToastProps>('[toast] show LoadingMsg', {
      detail: NewToast({
        ...loadingMsg,
        id: uid,
        type: 'loading',
        autoClose: false,
      }),
    });
    document.dispatchEvent(customEvent);

    return uid;
  },
  close(id: string) {
    customEventService.dispatch<ToastEventListenerType, string>('[toast] close Toast', {
      detail: id,
    });
  },
  closeAll() {
    customEventService.dispatch<ToastEventListenerType, void>('[toast] close all toast');
  },
};

import Title, { type TitleProps } from 'afterdoc-design-system/components/Molecules/Title/Title';
import { type ReactNode, useEffect, useRef } from 'react';

export interface ModalProps {
  id?: string;
  contents: ReactNode;
  width?: number | string;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  titleProps?: TitleProps;
  bottomButtons?: ReactNode;
  disableEnter?: boolean;
  disableEscape?: boolean;
}

export default function Modal({
  titleProps,
  id,
  contents,
  width,
  bottomButtons,
  disableEnter,
  disableEscape,
  onConfirm,
  onCancel,
  onClose,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !disableEnter) {
        event.preventDefault();
        event.stopPropagation();
        onClose?.();
        onConfirm?.();
      } else if (event.key === 'Escape' && !disableEscape) {
        event.preventDefault();
        event.stopPropagation();
        onClose?.();
        onCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [disableEnter, disableEscape, onConfirm, onCancel, onClose]);

  return (
    <div
      className='fixed top-0 left-0 z-[9999] flex-full-center select-none bg-black900 bg-opacity-20'
      ref={modalRef}>
      <div
        className='relative rounded-r16 bg-white50 shadow-modal'
        id={id}
        style={{
          width: width ? (typeof width === 'number' ? `${width}px` : width) : 'w-fit',
        }}>
        {titleProps && (
          <Title
            titleStyle={{
              maxWidth: width ? undefined : '248px',
              wordBreak: 'break-all',
            }}
            {...titleProps}
          />
        )}
        <div className='break-words px-20 py-5'>
          <div
            className='w-full whitespace-pre-wrap text-Body12'
            style={{
              maxWidth: width ? undefined : '280px',
            }}>
            {contents}
          </div>
        </div>
        {bottomButtons && <div className='flex gap-8 px-20 py-20'>{bottomButtons}</div>}
      </div>
    </div>
  );
}

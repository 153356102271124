import { type MouseEvent, useRef, useState } from 'react';

export const useHandleClickBackdrop = (onClickBackdrop: () => void) => {
  const [isClickBackdrop, setIsClickBackdrop] = useState(false);
  const backdropRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsClickBackdrop(e.target === backdropRef?.current);
  };

  const handleMouseUp = () => {
    if (!isClickBackdrop) return;
    onClickBackdrop();
  };

  return {
    backdropRef,
    handleMouseDown,
    handleMouseUp,
  };
};

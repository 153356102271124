import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import FullDimmedLoading from './FullDimmedLoading';

export default function FullDimmedLoadingManager() {
  const [fullDimmedLoading, setIsFullDimmedLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleOn = () => {
      setIsFullDimmedLoading(true);
    };

    const handleOff = () => {
      setIsFullDimmedLoading(false);
    };

    document.addEventListener('pushFullDimmedLoading', handleOn as EventListener);
    document.addEventListener('popFullDimmedLoading', handleOff as EventListener);

    return () => {
      document.removeEventListener('pushFullDimmedLoading', handleOn as EventListener);
      document.removeEventListener('popFullDimmedLoading', handleOff as EventListener);
    };
  }, []);

  return <Portal>{fullDimmedLoading && <FullDimmedLoading />}</Portal>;
}

import { type RefObject, useEffect, useState } from 'react';

/**
 * 주어진 요소 외부를 클릭했을 때와 특정 키 이벤트가 발생했을 때 콜백을 실행하는 훅입니다.
 *
 * @template T - HTML 요소의 타입입니다.
 * @param {Object} params - 훅의 매개변수 객체입니다.
 * @param {string} params.id - 고유 식별자입니다.
 * @param {RefObject<T>} params.ref - 클릭 외부 감지를 위한 참조 객체입니다.
 * @param {RefObject<T>} params.contentRef - 콘텐츠 요소의 참조 객체입니다.
 * @param {RefObject<T>[]} [params.ignoreRefs] - 클릭 이벤트를 무시할 요소들의 참조 객체 배열입니다.
 * @param {() => void} params.onClose - 외부 클릭 또는 특정 키 이벤트 시 호출되는 콜백 함수입니다.
 * @param {(event: KeyboardEvent) => void} [params.keyEventHandler] - 키 이벤트가 발생했을 때 호출되는 콜백 함수입니다.
 * @param {(event: MouseEvent) => void} [params.clickEventHandler] - 클릭 이벤트가 발생했을 때 호출되는 콜백 함수입니다.
 *
 * @example
 * const ref = useRef(null);
 * const contentRef = useRef(null);
 * const ignoreRefs = [useRef(null), useRef(null)];
 *
 * useClickOutside({
 *   id: 'my-unique-id',
 *   ref,
 *   contentRef,
 *   ignoreRefs,
 *   onClose: () => console.info('Outside clicked or Escape pressed'),
 *   keyEventHandler: (event) => console.info('Key event:', event),
 *   clickEventHandler: (event) => console.info('Click event:', event),
 * });
 */
export const useClickOutside = <T extends HTMLElement>({
  id,
  ref,
  contentRef,
  ignoreRefs,
  onClose,
  keyEventHandler,
  clickEventHandler,
}: {
  id: string;
  ref: RefObject<T>;
  contentRef: RefObject<T>;
  ignoreRefs?: RefObject<T>[];
  onClose: (e?: MouseEvent) => void;
  keyEventHandler?: (event: KeyboardEvent) => void;
  clickEventHandler?: (event: MouseEvent) => void;
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const modalElement = ref.current;
    const ignoreElements = ignoreRefs?.map((ignoreRef) => ignoreRef.current) ?? [];

    if (mounted && modalElement) {
      const handleOutsideClick = (e: MouseEvent) => {
        e.stopPropagation();
        const targetElement = e.target as HTMLElement;

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const eventPath = e.composedPath ? e.composedPath() : (e as any).path;

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        if (eventPath.map((path: any) => path.className)?.includes(contentRef.current?.className)) {
          return;
        }

        const clickedInsideId = targetElement.getAttribute('data-click-outside-id');

        if (
          ref.current &&
          contentRef.current &&
          ignoreElements?.every((ignoreElement) => !ignoreElement?.contains(targetElement))
        ) {
          if (targetElement.closest('[data-ignore-click-outside]')) {
            return;
          }
          if (
            (!contentRef.current.contains(e.target as Node) && !ignoreRefs) ||
            (ignoreRefs &&
              !contentRef.current.contains(e.target as Node) &&
              !ignoreRefs?.some((ignoreRef) => ignoreRef.current?.contains(e.target as Node)) &&
              clickedInsideId !== id)
          ) {
            onClose(e);
            clickEventHandler?.(e);
          }
        }
      };

      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [mounted, ref, contentRef, ignoreRefs, clickEventHandler, onClose, id]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' &&
        Boolean(ref.current?.getAttribute('data-ignore-click-outside')) === true
      ) {
        onClose();
      }
      keyEventHandler?.(event);
    };

    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [keyEventHandler, onClose, ref.current]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute('data-click-outside-id', id);
    }
    if (ignoreRefs && ignoreRefs.length > 0) {
      for (const ignoreRef of ignoreRefs) {
        if (ignoreRef.current) {
          ignoreRef.current.setAttribute('data-click-outside-id', id);
        }
      }
    }
  }, [id, ref, ignoreRefs]);
};

import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import type { MouseEvent } from 'react';
import styles from './DownloadingProgress.module.css';

const CompleteButton = ({
  onFileOpenClickCallback,
}: { onFileOpenClickCallback: (e: MouseEvent<HTMLButtonElement>) => void }) => (
  <TextButton
    className='rounded-r6 bg-blue500 px-14 text-Body11'
    textColor='white50'
    onClick={onFileOpenClickCallback}>
    파일 열기
  </TextButton>
);

const ErrorButton = ({
  onReloadClickCallback,
}: { onReloadClickCallback: (e: MouseEvent<HTMLButtonElement>) => void }) => (
  <IconButton onClick={onReloadClickCallback} icon='replay' size={24} color='white50' />
);

const DownloadingProgress = ({
  percentage,
  isPercentage = true,
}: { percentage: number; isPercentage: boolean }) => {
  const filteredPercentage = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;
  return (
    <>
      <div className='w-[250px] flex-center'>
        <progress id='file' value={percentage} max='100' className={styles.progressBar} />
      </div>
      {isPercentage && <span className='text-Body11 text-white50'>{filteredPercentage}%</span>}
    </>
  );
};

export default function RenderProgressRightContent({
  status,
  percentage,
  onReloadClickCallback,
  onFileOpenClickCallback,
  isPercentage = true,
}: {
  status: string;
  percentage: number;
  onReloadClickCallback: (e: MouseEvent<HTMLButtonElement>) => void;
  onFileOpenClickCallback: (e: MouseEvent<HTMLButtonElement>) => void;
  isPercentage?: boolean;
}) {
  //key가 필요없지만 biome 설정 때문에 넣음.
  const componentsMap = new Map<string, JSX.Element | null>([
    [
      'complete',
      <CompleteButton key='complete' onFileOpenClickCallback={onFileOpenClickCallback} />,
    ],
    ['error', <ErrorButton key='error' onReloadClickCallback={onReloadClickCallback} />],
    [
      'downloading',
      <DownloadingProgress key='downloading' percentage={percentage} isPercentage={isPercentage} />,
    ],
  ]);

  return componentsMap.get(status) || null;
}

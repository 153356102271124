import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { type ReactNode, useRef } from 'react';
import TabProvider from '../contexts/tab-context';
import TabIndicator from './TabIndicator';

export interface TabsProps<T> {
  value: T;
  onChange: (i: T) => void;
  children: ReactNode;
  className?: string;
}

export default function Tabs<T>({ value, onChange, children, className }: TabsProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TabProvider tabRef={ref} value={value} onChange={onChange}>
      <nav
        ref={ref}
        className={customTwMerge(
          'relative flex min-h-34 min-w-90 select-none list-none items-center',
          className,
        )}>
        {children}
        <TabIndicator />
      </nav>
    </TabProvider>
  );
}

import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ReactNode } from 'react';

export interface BigImageProps {
  children?: ReactNode;
  onClickNoImage?: () => void;
  onClickImage?: () => void;
  width: number | string;
  height: number | string;
  text?: string;
  className?: string;
}

export default function BigImage({
  children,
  onClickNoImage,
  onClickImage,
  width,
  height,
  text = '이미지 변경하기',
  className,
}: BigImageProps) {
  const hasNoImage = !children;

  return (
    <div
      className={customTwMerge(
        'group relative cursor-pointer overflow-hidden rounded-r16 border border-white600',
        className,
      )}
      onClick={hasNoImage ? onClickNoImage : onClickImage}
      style={{
        width: SHARED_UTILS.css.getCssSizeValue(width),
        height: SHARED_UTILS.css.getCssSizeValue(height),
      }}>
      {children}
      <div className='absolute inset-0 bg-black900 opacity-0 transition-opacity duration-300 group-hover:opacity-40' />
      {hasNoImage ? (
        <div className='absolute-center h-[24px] w-[24px] flex-center rounded-full bg-black800 bg-opacity-50'>
          <Icon name='plus' color='white50' size={20} />
        </div>
      ) : (
        <div className='absolute-center flex-col-center gap-6 opacity-0 transition-opacity duration-300 group-hover:opacity-100'>
          <Icon name='image-filter' color='white50' size={28} />
          <div className='text-Header14 text-white50'>{text}</div>
        </div>
      )}
    </div>
  );
}

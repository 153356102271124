import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import Modal, { type ModalProps } from './Modal';
import { modalService } from './Modal.service';
import type { ModalListProps } from './Modal.type';

export default function ModalManager() {
  const [modals, setModals] = useState<ModalProps[]>([]);

  useEffect(() => {
    const handlePush = (event: CustomEvent<ModalListProps>) => {
      setModals((prev) => [...prev, event.detail]);
    };

    const handlePop = () => {
      setModals((prev) => prev.slice(0, -1));
    };

    const handlePopById = (event: CustomEvent<string>) => {
      setModals((prev) => prev.filter((modal) => modal.id !== event.detail));
    };

    const handlePopAll = () => {
      setModals([]);
    };

    document.addEventListener('pushModal', handlePush as EventListener);
    document.addEventListener('popModal', handlePop);
    document.addEventListener('popModalById', handlePopById as EventListener);
    document.addEventListener('popAllModals', handlePopAll);

    return () => {
      document.removeEventListener('pushModal', handlePush as EventListener);
      document.removeEventListener('popModal', handlePop);
      document.removeEventListener('popModalById', handlePopById as EventListener);
      document.removeEventListener('popAllModals', handlePopAll);
    };
  }, []);

  return (
    <Portal>
      {modals.map((modal) => (
        <Modal
          {...modal}
          key={modal.id}
          onClose={() => {
            modal.id ? modalService.popById(modal.id) : modalService.popAll();
          }}
          onCancel={modal.onCancel}
          onConfirm={modal.onConfirm}
        />
      ))}
    </Portal>
  );
}

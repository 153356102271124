import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

export interface TextButtonProps {
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  children: ReactNode;
  disabled?: boolean;
  iconProps?: IconProps;
  textColor?: keyof typeof Color;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

export default function TextButton({
  onClick,
  children,
  disabled,
  iconProps,
  textColor = 'black200',
  className,
  type,
}: TextButtonProps) {
  return (
    <button
      disabled={disabled}
      className={customTwMerge(
        'w-fit flex-row-center select-none gap-2 py-2 pr-4 pl-8 text-Body11',
        className,
      )}
      style={{
        color: disabled ? Color.disabled : Color[textColor],
      }}
      onClick={onClick}
      type={type ?? 'button'}>
      {children}
      {iconProps && <Icon color={iconProps.color ?? textColor} {...iconProps} />}
    </button>
  );
}

import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import {
  type InputHTMLAttributes,
  type LabelHTMLAttributes,
  type ReactNode,
  forwardRef,
  useEffect,
  useId,
  useState,
} from 'react';

type BaseAttributes = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'checked'>;

type ToggleSize = 'small' | 'big';

export interface ToggleProps extends Omit<BaseAttributes, 'size'> {
  defaultChecked?: boolean;
  label?: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  toggleSize?: ToggleSize;
  checked?: boolean;
  wrapperClassName?: string;
}

interface ToggleLabelProps {
  label: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
}

const computeClassName = (checked: boolean, disabled?: boolean) => {
  if (checked && disabled) {
    return 'bg-blue200 border-blueLight';
  }
  if (checked) {
    return 'border-blue500 bg-blue600';
  }
  if (disabled) {
    return 'border-white600 bg-white100';
  }
  return '';
};

const ToggleLabel = ({ label, labelProps, id, checked, disabled }: ToggleLabelProps) => {
  const uid = useId();

  return (
    <label
      {...labelProps}
      htmlFor={id || uid}
      className={customTwMerge(
        'select-none text-Body12',
        checked ? 'text-black500' : disabled ? 'text-disabled' : 'text-black200',
        labelProps?.className,
      )}
      aria-disabled={disabled}>
      {label}
    </label>
  );
};

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      label,
      labelProps,
      toggleSize = 'big',
      id,
      className,
      wrapperClassName,
      checked,
      onChange,
      defaultChecked = false,
      disabled,
      ...toggleProps
    },
    ref,
  ) => {
    const uid = useId();
    const [isChecked, setIsChecked] = useState(defaultChecked);

    useEffect(() => {
      if (checked !== undefined) {
        setIsChecked(checked);
      }
    }, [checked]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        e.stopPropagation();
        onChange(e);
      }
      if (checked === undefined) {
        setIsChecked(e.target.checked);
      }
    };

    return (
      <div className={customTwMerge('flex min-h-30 items-center gap-10 py-3', wrapperClassName)}>
        <div
          className={customTwMerge(
            'relative inline-block shrink-0',
            toggleSize === 'big' ? 'h-18 w-34' : 'h-12 w-26',
            className,
          )}>
          <input
            {...toggleProps}
            ref={ref}
            id={id || uid}
            className='absolute left-0 h-0 w-0 opacity-0'
            type='checkbox'
            checked={checked !== undefined ? checked : isChecked}
            disabled={disabled}
            onChange={handleChange}
          />
          <label
            className={customTwMerge(
              'block cursor-pointer overflow-hidden rounded-full border border-white600 bg-white100 disabled:border-white400 disabled:bg-disabled',
              computeClassName(isChecked, disabled),
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              toggleSize === 'big' ? 'h-18 w-34' : 'h-12 w-26',
            )}
            htmlFor={id || uid}>
            <span
              className={customTwMerge(
                'absolute top-2 left-2 rounded-full transition-transform disabled:bg-white400',
                isChecked ? 'bg-white50' : 'bg-white800',
                toggleSize === 'big' ? 'h-14 w-14' : 'h-8 w-8',
                isChecked && toggleSize === 'big' && 'translate-x-16 transform',
                isChecked && toggleSize === 'small' && 'translate-x-14 transform',
              )}
            />
          </label>
        </div>
        {label && (
          <ToggleLabel
            label={label}
            labelProps={labelProps}
            id={id || uid}
            checked={isChecked}
            disabled={disabled}
          />
        )}
      </div>
    );
  },
);

Toggle.displayName = 'Toggle';

export default Toggle;

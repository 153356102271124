import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAnimationToggle } from 'afterdoc-design-system/hooks/use-animation-toggle';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import {
  type CSSProperties,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import type { TooltipPosition } from './Tooltip.type';

const OFFSET = 8;

const calcTooltipPos = (target: HTMLElement, tooltip: HTMLElement, pos: TooltipPosition) => {
  const { top, left, height } = target.getBoundingClientRect();
  const tooltipHeight = tooltip.clientHeight;
  const tooltipWidth = tooltip.clientWidth;

  switch (pos) {
    case 'top':
      return { top: top - tooltipHeight - OFFSET, left: left / 2 - tooltipWidth / 2 };
    case 'topRight':
      return { top: top - tooltipHeight - OFFSET, left: left };
    case 'right':
      return { top: top + height / 2 - tooltipHeight / 2, left: left + OFFSET };
    case 'bottomRight':
      return { top: top + height, left: left };
    case 'bottom':
      return { top: top + height + OFFSET, left: left / 2 - tooltipWidth / 2 };
    case 'bottomLeft':
      return { top: top + height + OFFSET, left: left - tooltipWidth };
    case 'left':
      return { top: top + height / 2 - tooltipHeight / 2, left: left - tooltipWidth - OFFSET };
    case 'topLeft':
      return { top: top - tooltipHeight - OFFSET, left: left - tooltipWidth };
    default:
      return { top: 0, left: 0 };
  }
};

export interface HoverComponentProps {
  children: ReactNode;
  content: ReactNode;
  position?: TooltipPosition;
  show?: boolean;
  portalZindex?: number;
  wrapperProps?: React.HTMLAttributes<HTMLSpanElement>;
  style?: CSSProperties;
}

export default function HoverComponent({
  children,
  content,
  position = 'top',
  show = true,
  portalZindex,
  wrapperProps = {},
  style = {},
}: PropsWithChildren<HoverComponentProps>) {
  const ref = useRef<HTMLElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const { className, style: wrapperStyle, ...restWrapperProps } = wrapperProps;
  const defaultZIndex = style?.zIndex || 10;

  const tooltipPos = useRef<CSSProperties>({});

  const [zIndex, setZIndex] = useState<CSSProperties['zIndex']>(defaultZIndex);

  const setTooltipPosition = useCallback(() => {
    if (ref.current && tooltipRef.current) {
      const { top, left } = calcTooltipPos(ref.current, tooltipRef.current, position);

      tooltipPos.current = { top, left };
    }
  }, [position]);

  const {
    mounted,
    trigger: opacity,
    onShow,
    onHide,
  } = useAnimationToggle({ transitionDuration: 150, onShow: setTooltipPosition, openDelay: 10 });

  const onPointerEnter = () => {
    onShow();
    setZIndex(defaultZIndex);
  };

  const onPointerLeave = () => onHide();

  return (
    <span
      {...restWrapperProps}
      ref={ref}
      style={wrapperStyle}
      onMouseEnter={onPointerEnter}
      onMouseLeave={onPointerLeave}
      className={customTwMerge('inline-block cursor-pointer', className)}>
      {children}
      {mounted && show && (
        <Portal
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: portalZindex ?? 10,
          }}>
          <div
            ref={tooltipRef}
            style={{
              ...style,
              ...tooltipPos.current,
              position: 'absolute',
              transition: 'opacity 150ms ease',
              opacity,
              zIndex,
            }}>
            {content}
          </div>
        </Portal>
      )}
    </span>
  );
}

import { checkApiResponse, isApiErrorWithCode } from 'utils/api/check-api-response';

export const api = {
  /**
   * AfterDoc API 호출 시 서버에서 정상적으로 데이터가 내려왔는지 확인하는 공통 체크 함수
   *
   * 서버에서 내려오는 code 값이 0 이고 data 가 [null / not empty] 인 경우 전달 한 해당 타입을 리턴한다.
   *
   * @param {T} response: ApiResponseData<T> -
   * @return {T} 최종적으로 사용할 데이터 타입
   *
   * @example
   *  const response = await apiClient.v3.apiHospitalsElUpdate(hospital);
   *  return SHARED_UTILS.api.checkApiResponse<HospitalRepresentImage>(response.data);
   *
   *  성공 시 HospitalRepresentImage 를 리턴한다.
   */
  checkApiResponse,

  /**
   * AfterDoc API 호출 이후 오류에 대한 메세지 및 코드를 확인할 때 일반 네트워크 오류가 아닌 서버에서 내려온 에러코드인지 확인하는 함수
   *
   * @param {Error} error: API 호출 후 onError 콜백으로 리턴된 Error
   * @param {number | number[] | undefined} code: undefined인 경우 애프터닥 오류인지만 확인, number | number[] 인 경우
   * 애프터닥 오류 + 코드까지 일치하는 확인
   *
   * @example
   *
   *  const mutation = useMutation({
   *     mutationFn: (params: CreateHospitalAccountFormValues) => createHospitalAccount(params),
   *      ....생략
   *     onError: (error) => handleMutationError(error),
   *   });
   *
   *   const handleMutationError = (error: Error) => {
   *     if (SHARED_UTILS.api.isApiErrorWithCode(error, 400)) {
   *        // 서버에서 내려준 오류메세지 사용하기
   *        toastService.errorMsg({ text: error.message }}
   *     }
   *   };
   *
   */
  isApiErrorWithCode,
};

import type { Dispatch, SetStateAction } from 'react';
import type { ToastProps } from '../Toast.type';

interface UseHandleToastListProps {
  setToasts: Dispatch<SetStateAction<ToastProps[]>>;
}

export const useHandleToastList = ({ setToasts }: UseHandleToastListProps) => {
  const closeByIdToast = () => {
    setToasts([]);
  };

  const closeAllToast = () => {
    setToasts((prevToasts) => {
      const updatedToast = prevToasts.map((toast) => {
        return { ...toast, autoCloseTimestamp: -1 };
      });
      return updatedToast;
    });
  };

  return {
    closeByIdToast,
    closeAllToast,
  };
};

import { createUserPersistedAtom } from 'utils/jotai/create-user-persisted-atom';
import { createPersistedAtom } from './create-persisted-atom';
import { getLocalStorageValue } from './get-local-storage-value';
import { setLocalStorageValue } from './set-local-storage-value';

export const jotai = {
  /**
   * 로컬 스토리지에 지속되는 Jotai 아톰을 생성하는 함수입니다.
   *
   * @template T - 아톰의 값의 타입입니다.
   * @param {string} key - 로컬 스토리지 키입니다.
   * @param {T} initialValue - 초기 값입니다.
   * @returns {Atom<T>} 로컬 스토리지에 지속되는 Jotai 아톰입니다.
   */
  createPersistedAtom,
  /**
   * 로컬 스토리지에 지속되는 Jotai 아톰을 생성하는 함수입니다.
   *
   * @template T - 아톰의 값의 타입입니다.
   * @param {string} userID - 로그인 한 유저에 따라 다른 값을 읽기/쓰기를기 위한 로컬 스트로지 키
   * @param {string} key - 로컬 스토리지 키입니다.
   * @param {T} initialValue - 초기 값입니다.
   * @returns {Atom<T>} 로컬 스토리지에 지속되는 Jotai 아톰입니다.
   */
  createUserPersistedAtom,
  /**
   * 로컬 스토리지에서 값을 가져오는 유틸리티 함수입니다.
   *
   * @template T - 저장된 값의 타입입니다.
   * @param {string} key - 로컬 스토리지 키입니다.
   * @param {T} initialValue - 초기 값입니다.
   * @returns {T} 로컬 스토리지에 저장된 값 또는 초기 값입니다.
   */
  getLocalStorageValue,
  /**
   * 로컬 스토리지에 값을 저장하는 유틸리티 함수입니다.
   *
   * @template T - 저장할 값의 타입입니다.
   * @param {string} key - 로컬 스토리지 키입니다.
   * @param {T} value - 저장할 값입니다.
   */
  setLocalStorageValue,
};

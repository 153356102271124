interface CheckWindowSizeProps {
  clientX: number;
  clientY: number;
  clientWidth: number;
  clientHeight: number;
}

const OFFSET_GAP = 16;

export const useCheckTargetOverflowScreen = ({
  clientX,
  clientY,
  clientWidth,
  clientHeight,
}: CheckWindowSizeProps) => {
  const { innerWidth, innerHeight } = window;
  const SCREEN_OFFSET = OFFSET_GAP * 2;

  const isWidthOverflow = !(Math.sign(innerWidth - clientX - clientWidth - SCREEN_OFFSET) + 1);
  const isHeightOverflow = !(Math.sign(innerHeight - (clientY + clientHeight + SCREEN_OFFSET)) + 1);

  return { isWidthOverflow, isHeightOverflow, OFFSET_GAP };
};

import { Color } from '../../../tailwind-base/src/styles/color';
import { convertHexToRgb } from '../color/convert-hex-to-rgb';
import { convertRgbToHsb } from '../color/convert-rgb-to-hsb';

export interface MakeTextColorWithBgColorProps {
  bgColor?: string;
  options?: {
    hueRange: [number, number];
    saturationRange: [number, number];
    brightnessRange: [number, number];
    lightTextColor: string;
    darkTextColor: string;
  };
}

export const makeTextColorWithBgColor = ({
  bgColor,
  options = {
    hueRange: [0, 360],
    saturationRange: [0, 50],
    brightnessRange: [70, 100],
    lightTextColor: Color.black800,
    darkTextColor: Color.white50,
  },
}: MakeTextColorWithBgColorProps): string => {
  const { r, g, b } = convertHexToRgb(bgColor ?? Color.blue500);
  const { h, s, b: brightness } = convertRgbToHsb({ r, g, b });

  const { hueRange, saturationRange, brightnessRange, lightTextColor, darkTextColor } = options;

  // HSB 값을 기반으로 텍스트 색상을 결정
  if (
    h >= hueRange[0] &&
    h <= hueRange[1] &&
    s >= saturationRange[0] &&
    s <= saturationRange[1] &&
    brightness >= brightnessRange[0] &&
    brightness <= brightnessRange[1]
  ) {
    return lightTextColor;
  }
  return darkTextColor;
};

import { atom } from 'jotai';
import { getLocalStorageValue } from 'utils/jotai/get-local-storage-value';
import { setLocalStorageValue } from 'utils/jotai/set-local-storage-value';

export const createUserPersistedAtom = <T>(userId: string, key: string, initialValue: T) => {
  // 사용자별 키 생성
  const storageKey = `${key}_${userId}`;

  const baseAtom = atom<T>(getLocalStorageValue(storageKey, initialValue));

  return atom(
    (get) => get(baseAtom),
    (_get, set, newValue: T) => {
      set(baseAtom, newValue);
      setLocalStorageValue(storageKey, newValue);
    },
  );
};

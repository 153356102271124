import { useEffect } from 'react';

interface UseCloseDropdownProps {
  dropdownId: string;
  setIsToggle: (value: boolean) => void;
}

export const useCloseDropdown = ({ dropdownId, setIsToggle }: UseCloseDropdownProps) => {
  useEffect(() => {
    const handleCloseDropdown = (event: CustomEvent<string>) => {
      if (event.detail !== dropdownId) {
        setIsToggle(false);
      }
    };

    document.addEventListener('closeDropdown', handleCloseDropdown as EventListener);

    return () => {
      document.removeEventListener('closeDropdown', handleCloseDropdown as EventListener);
    };
  }, [dropdownId, setIsToggle]);
};

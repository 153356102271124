import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import _BaseTag, { type BaseTagProps } from '../shared/_BaseTag';
import './GradientTag.scss';

export interface GradientTagProps extends Omit<BaseTagProps, 'borderColor'> {
  borderGradient?: string;
}

export default function GradientTag({
  children,
  tagSize = 'small',
  textColor,
  iconProps,
  onClick,
  className,
  style,
}: GradientTagProps) {
  return (
    <div
      className={customTwMerge(
        'gradient-tag',
        'pl-8',
        iconProps ? 'pr-6' : 'pr-8',
        tagSize === 'small' ? 'py-3' : 'py-1',
        'rounded-r10 text-Caption9 text-white50',
        className,
      )}
      style={style}>
      <_BaseTag
        tagSize={tagSize}
        textColor={textColor}
        iconProps={iconProps}
        onClick={onClick}
        textClassName='gradient-tag__text'>
        {children}
        {iconProps && <Icon {...iconProps} />}
      </_BaseTag>
    </div>
  );
}

import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { forwardRef } from 'react';
import type { TooltipProps } from './Tooltip.type';

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({ text, ...props }, ref) => {
  const { className, ...rest } = props;

  return (
    <span
      ref={ref}
      className={customTwMerge(
        'inline-block w-max max-w-[500px] cursor-default select-none break-words rounded-r6 bg-black700 bg-opacity-80 px-10 py-2 text-left text-Body10 text-white50',
        className,
      )}
      {...rest}>
      {text}
    </span>
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;

export const phoneHyphenText = (text: string): string => {
  const originalPhoneNumber = text.replace(/-/g, '');

  if (originalPhoneNumber.length < 4) {
    return text;
  }
  if (originalPhoneNumber.length < 7) {
    return originalPhoneNumber.replace(/(\d{3})(\d{1,4})/, '$1-$2');
  }
  if (originalPhoneNumber.length < 11) {
    return originalPhoneNumber.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
  }
  return originalPhoneNumber.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
};

import { uniqueId } from 'lodash-es';
import type { ReactNode } from 'react';
import type { DialogListProps, DialogProps } from './Dialog.type';

export const dialogService = {
  push(contents: ReactNode, props?: DialogProps): string {
    const id = props?.id || uniqueId();

    const dialogItem: DialogListProps = {
      titleProps: props?.titleProps,
      hasCloseButton: props?.hasCloseButton ?? false,
      wrapperClassName: props?.wrapperClassName ?? '',
      id,
      show: true,
      contents,
      width: props?.width,
      onKeydown: props?.onKeydown,
      onClose: props?.onClose,
      isClosable: props?.isClosable,
    };

    const customEvent = new CustomEvent('pushDialog', { detail: dialogItem });
    document.dispatchEvent(customEvent);

    return id;
  },

  pop() {
    const customEvent = new CustomEvent('popDialog');
    document.dispatchEvent(customEvent);
  },

  popById(id: string) {
    const customEvent = new CustomEvent('popDialogById', { detail: id });
    document.dispatchEvent(customEvent);
  },

  popAll() {
    const customEvent = new CustomEvent('popAllDialogs');
    document.dispatchEvent(customEvent);
  },
};

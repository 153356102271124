import {
  type ContextMenuListProps,
  type ContextMenuOption,
  type OffsetOption,
  PositionType,
} from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import { uniqueId } from 'lodash-es';
import type { CSSProperties, ReactNode } from 'react';

const PORTAL_STYLE: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 100,
};

export const contextMenuService = {
  push(event: React.MouseEvent, content: ReactNode, option?: ContextMenuOption): string {
    const contentOption: ContextMenuOption = option || {};
    const {
      customId,
      onClose: onCloseCb,
      positionType = PositionType.ELEMENT_POSITION,
      backdropStyle,
      xOffset = 0,
      yOffset = 0,
    } = contentOption;

    const id = customId ?? uniqueId('pop-in');
    const parent: Element = event.currentTarget || document.body;
    const onClose = onCloseCb ? onCloseCb : () => contextMenuService.popById(id);

    const contextMenuItem: ContextMenuListProps = {
      id,
      parent,
      content,
      onClose,
      positionType,
      backdropStyle,
      portalStyle: PORTAL_STYLE,
      offsetOption: { event, xOffset, yOffset },
    };

    contextMenuUtilService.createCustomEvent('contextMenuPush', { detail: contextMenuItem });

    return id;
  },

  pop() {
    contextMenuUtilService.createCustomEvent('contextMenuPop');
  },

  popById(id: string) {
    contextMenuUtilService.createCustomEvent('contextMenuPopById', { detail: id });
  },

  popAll() {
    contextMenuUtilService.createCustomEvent('contextMenuPopAll');
  },
};

export const contextMenuUtilService = {
  createCustomEvent(eventName: string, eventInitDict?: CustomEventInit<unknown>) {
    const customEvent = new CustomEvent(eventName, eventInitDict);
    document.dispatchEvent(customEvent);
  },

  getElementPos(parent: Element, offsetOption: OffsetOption): CSSProperties {
    const { top, left } = parent.getBoundingClientRect();
    const { xOffset, yOffset } = offsetOption;

    return { top: `${top + yOffset}px`, left: `${left + xOffset}px` };
  },

  getMousePos(mouseEvent: React.MouseEvent, offsetOption: OffsetOption): CSSProperties {
    const { clientX, clientY } = mouseEvent;
    const { xOffset, yOffset } = offsetOption;

    return { top: `${clientY + yOffset}px`, left: `${clientX + xOffset}px` };
  },
};

import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon, { type IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CSSProperties, ReactNode } from 'react';

type ButtonSize = 'small' | 'big';
type EnableBtnColors = 'blue' | 'red' | 'secondary';

const OUTLINED_BUTTON_COLORS_STYLE: {
  [key in EnableBtnColors]: {
    bgStyle: string;
    textStyle: string;
    borderStyle: string;
  };
} = {
  blue: {
    bgStyle:
      'bg-white50 disabled:bg-transparent hover:bg-blue50 focus:bg-white50 active:bg-blue100',
    textStyle: 'text-blue500 hover:text-blue600 active:text-blue700 disabled:text-disabled',
    borderStyle:
      'border-blue500 hover:border-blue600 active:border-blue700 disabled:border-disabled',
  },

  red: {
    bgStyle: 'bg-white50 disabled:bg-transparent hover:bg-red50 focus:bg-white50 active:bg-red100',
    textStyle: 'text-red500 hover:text-red600 active:text-red700 disabled:text-disabled',
    borderStyle: 'border-red500 hover:border-red600 active:border-red700 disabled:border-disabled',
  },
  secondary: {
    bgStyle:
      'bg-white50 disabled:bg-transparent hover:bg-white100 focus:bg-white50 active:bg-white200',
    textStyle:
      'text-white700 hover:text-white700 focus:text-white800 active:text-white800 disabled:text-disabled',
    borderStyle:
      'border-white700 hover:border-white700 focus:border-white800 active:border-white800 disabled:border-disabled',
  },
};

const OUTLINED_BUTTON_SIZES: {
  [key in ButtonSize]: ButtonSize;
} = {
  small: 'small',
  big: 'big',
};

export const OUTLINED_BUTTON_COLORS = Object.keys(OUTLINED_BUTTON_COLORS_STYLE).map((key) =>
  key.replace('Style', ''),
) as EnableBtnColors[];

export const OUTLINED_BUTTON_SIZES_ARR = Object.keys(OUTLINED_BUTTON_SIZES) as ButtonSize[];

interface ExtendedIconProps extends IconProps {
  direction?: 'left' | 'right';
}

export interface OutlinedButtonProps<T extends ButtonSize> {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  buttonSize?: T;
  iconProps?: T extends 'big' ? ExtendedIconProps : never;
  btnColor?: EnableBtnColors;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  style?: CSSProperties;
}

const ICON_COLORS = {
  blue: 'blue500',
  red: 'red500',
  secondary: 'white800',
} as const;

export default function OutlinedButton({
  children,
  onClick,
  disabled,
  buttonSize = 'big',
  iconProps,
  btnColor = 'blue',
  type = 'button',
  className,
  style,
}: OutlinedButtonProps<ButtonSize>) {
  const buttonSizeClassName =
    buttonSize === 'big'
      ? 'text-Body13 rounded-r10 py-5 px-19'
      : 'text-Body11 rounded-r6 py-2 px-13';

  return (
    <button
      disabled={disabled}
      className={customTwMerge(
        'w-fit select-none border',
        iconProps && 'flex-row-center gap-4',
        OUTLINED_BUTTON_COLORS_STYLE[btnColor].bgStyle,
        OUTLINED_BUTTON_COLORS_STYLE[btnColor].textStyle,
        OUTLINED_BUTTON_COLORS_STYLE[btnColor].borderStyle,
        buttonSizeClassName,
        className,
      )}
      onClick={onClick}
      type={type}
      style={style}>
      {iconProps && buttonSize === 'big' && iconProps.direction === 'left' && (
        <Icon color={iconProps.color ?? ICON_COLORS[btnColor]} {...iconProps} />
      )}
      {children}
      {iconProps && buttonSize === 'big' && iconProps.direction !== 'left' && (
        <Icon color={iconProps.color ?? ICON_COLORS[btnColor]} {...iconProps} />
      )}
    </button>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';

// 개발자 - 강태웅 개인 API 키 사용 중 (추후 삭제 요망) -> 백엔드에서 처리할 예정
const API_URL = 'https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo';
const SERVICE_KEY =
  'jBzp3eESgbbfeTBcJngxaAUQTb56ogm3Xy7j0XYeQCpc3xLkV6LIcbyC1w4EYiQ10VjwnNI4SL/SmjwFKMSKMA==';

export const useHolidays = (year: number, month: number) => {
  const [holidays, setHolidays] = useState<Date[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHolidays = async () => {
      setLoading(true);
      try {
        const response = await axios.get(API_URL, {
          params: {
            serviceKey: SERVICE_KEY,
            solYear: year,
            solMonth: month.toString().padStart(2, '0'),
          },
        });

        if (response.data.response.body.items) {
          const holidays =
            response.data.response.body.items.item.length > 1
              ? response.data.response.body.items.item.map(
                  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                  (item: any) =>
                    new Date(
                      item.locdate.toString().slice(0, 4),
                      item.locdate.toString().slice(4, 6) - 1,
                      item.locdate.toString().slice(6, 8),
                    ),
                )
              : [
                  new Date(
                    response.data.response.body.items.item.locdate.toString().slice(0, 4),
                    response.data.response.body.items.item.locdate.toString().slice(4, 6) - 1,
                    response.data.response.body.items.item.locdate.toString().slice(6, 8),
                  ),
                ];
          setHolidays(holidays);
        } else {
          setHolidays([]);
        }
      } catch (error) {
        console.error('Error fetching holidays:', error);
        setHolidays([]);
      } finally {
        setLoading(false);
      }
    };

    fetchHolidays();
  }, [year, month]);

  return { holidays, loading };
};

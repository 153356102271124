import { atom } from 'jotai';
import { getLocalStorageValue } from './get-local-storage-value';
import { setLocalStorageValue } from './set-local-storage-value';

export const createPersistedAtom = <T>(key: string, initialValue: T) => {
  const baseAtom = atom<T>(getLocalStorageValue(key, initialValue));

  const persistedAtom = atom(
    (get) => get(baseAtom),
    (_get, set, newValue: T) => {
      set(baseAtom, newValue);
      setLocalStorageValue(key, newValue);
    },
  );

  return persistedAtom;
};

import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import Dialog from './Dialog';
import { dialogService } from './Dialog.service';
import type { DialogListProps } from './Dialog.type';

export default function DialogManager() {
  const [dialogs, setDialogs] = useState<DialogListProps[]>([]);

  useEffect(() => {
    const handlePush = (event: CustomEvent<DialogListProps>) => {
      setDialogs((prev) => [...prev, event.detail]);
    };

    const handlePop = () => {
      setDialogs((prev) => prev.slice(0, -1));
    };

    const handlePopById = (event: CustomEvent<string>) => {
      setDialogs((prev) => prev.filter((dialog) => dialog.id !== event.detail));
    };

    const handlePopAll = () => {
      setDialogs([]);
    };

    document.addEventListener('pushDialog', handlePush as EventListener);
    document.addEventListener('popDialog', handlePop);
    document.addEventListener('popDialogById', handlePopById as EventListener);
    document.addEventListener('popAllDialogs', handlePopAll);

    return () => {
      document.removeEventListener('pushDialog', handlePush as EventListener);
      document.removeEventListener('popDialog', handlePop);
      document.removeEventListener('popDialogById', handlePopById as EventListener);
      document.removeEventListener('popAllDialogs', handlePopAll);
    };
  }, []);

  return (
    <Portal>
      {dialogs.map((dialog) => (
        <Dialog
          titleProps={dialog.titleProps}
          key={dialog.id}
          id={dialog.id}
          contents={dialog.contents}
          hasCloseButton={dialog.hasCloseButton}
          width={dialog.width}
          wrapperClassName={dialog.wrapperClassName}
          isClosable={dialog.isClosable}
          onClose={() => {
            if (dialog.onClose) {
              dialog.onClose();
            } else {
              dialog.id ? dialogService.popById(dialog.id) : dialogService.pop();
            }
          }}
        />
      ))}
    </Portal>
  );
}

import { type MouseEvent, useRef, useState } from 'react';
import { throttle } from 'lodash-es';

export type PageOffset = {
  clientX: number;
  clientY: number;
};

export const usePointerEvent = () => {
  const [cursorPosition, setCursorPosition] = useState<PageOffset | null>(null);

  const throttled = useRef(
    throttle((position: PageOffset | null) => setCursorPosition(position), 10),
  );

  const getMouseEventPosition = (e: MouseEvent<HTMLDivElement>) => {
    throttled.current({ clientX: e.clientX, clientY: e.clientY });
  };

  const handlePointerEnter = (e: MouseEvent<HTMLDivElement>) => {
    getMouseEventPosition(e);
  };

  const handlePointerLeave = () => {
    throttled.current(null);
  };

  return {
    cursorPosition,
    onPointerEnter: handlePointerEnter,
    onPointerMove: handlePointerEnter,
    onPointerLeave: handlePointerLeave,
  };
};

import type { ContextMenuListProps } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import { ContextMenuItem } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenuItem';
import { useContextMenuCustomEventListener } from 'afterdoc-design-system/components/Molecules/ContextMenu/functions/use-context-menu-custom-event-listener';
import { useEffect, useState } from 'react';

const contextMenuManagerId = 'contextMenuManager';

export default function ContextMenuManager() {
  const [trigger, setTrigger] = useState<boolean>(false);
  const [contextMenuList, setContextMenuList] = useState<ContextMenuListProps[]>([]);

  useContextMenuCustomEventListener({ contextMenuList, setContextMenuList });

  useEffect(() => {
    if (!contextMenuList.length) return;

    const handleKeydown = (e: KeyboardEvent) => {
      const { id, onClose } = contextMenuList[contextMenuList.length - 1];

      if (id && e.key === 'Escape' && !!onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [contextMenuList]);

  useEffect(() => {
    const target = document.getElementById(contextMenuManagerId);

    !target && setTrigger(true);
  }, []);

  return trigger ? (
    <div id={contextMenuManagerId}>
      {contextMenuList.map((item) => {
        return <ContextMenuItem key={item.id} {...item} />;
      })}
    </div>
  ) : null;
}

import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton, {
  type IconButtonProps,
} from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import LabelText, {
  type LabelTextProps,
} from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import type { IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  type InputHTMLAttributes,
  type ReactNode,
  type RefObject,
  forwardRef,
  useCallback,
} from 'react';

const INPUT_STYLE = {
  baseStyle:
    'border outline-none bg-white50 border-white600 text-black700 rounded-r10 text-Body12 placeholder:text-Body12 placeholder:text-black200 placeholder-black200 py-6 px-16 w-full',
  disabledStyle:
    'disabled:bg-white200 disabled:border-white600 disabled:cursor-not-allowed disabled:placeholder:text-black200 disabled:text-black200 disabled:placeholder-black200 disabled:placeholder:text-Body12',
  focusStyle: 'focus:border-blue500',
  iconLeftStyle: 'pl-42',
  iconRightStyle: 'pr-28',
};

type LabelDirection = 'vertical' | 'horizontal';

export interface TextInputLabelTextProps extends LabelTextProps {
  position?: LabelDirection;
}

export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'width'> {
  hasError?: boolean;
  width?: number | string;
  errorText?: ReactNode;
  leftIconProps?: IconProps;
  rightIconButtonProps?: IconButtonProps;
  inputNotAllowed?: boolean;
  inputWrapperClassName?: string;
  inputClassName?: string;
  errorTextClassName?: string;
  errorTextPosition?: LabelDirection;
  iconButtonRef?: RefObject<HTMLButtonElement>;
  wrapperRef?: RefObject<HTMLDivElement>;
  onClickInput?: () => void;
  label?: TextInputLabelTextProps;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      hasError = false,
      width,
      disabled = false,
      placeholder,
      errorText,
      leftIconProps,
      rightIconButtonProps,
      inputNotAllowed,
      inputWrapperClassName,
      inputClassName,
      errorTextClassName,
      errorTextPosition,
      onClickInput,
      iconButtonRef,
      wrapperRef,
      label,
      className,
      value,
      onChange,
      ...props
    },
    inputRef,
  ) => {
    const { baseStyle, disabledStyle, focusStyle, iconRightStyle, iconLeftStyle } = INPUT_STYLE;
    const labelPosition = label?.position ?? 'vertical';

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) return;

        e.stopPropagation();
        onClickInput?.();
      },
      [onClickInput],
    );

    const ErrorText = () => (
      <div className={customTwMerge('mt-4 text-Body10 text-red500', errorTextClassName)}>
        {errorText}
      </div>
    );

    return (
      <div
        className={customTwMerge(
          label && labelPosition === 'horizontal' && 'flex items-center gap-10',
          className,
        )}>
        {label && (
          <LabelText
            className={customTwMerge(
              labelPosition === 'vertical' ? 'mb-10' : 'py-7',
              label.className,
            )}
            {...label}
          />
        )}
        <div
          ref={wrapperRef}
          className={customTwMerge('relative', inputWrapperClassName)}
          onClick={handleClick}
          style={{
            width: SHARED_UTILS.css.getCssSizeValue(width),
          }}>
          {leftIconProps && (
            <span className='absolute inset-y-0 left-0 flex items-center pl-10'>
              <Icon {...leftIconProps} />
            </span>
          )}
          <div className='flex flex-col'>
            <input
              ref={inputRef}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={inputNotAllowed}
              value={value}
              onChange={onChange}
              className={customTwMerge(
                inputNotAllowed && 'cursor-pointer',
                baseStyle,
                leftIconProps && iconLeftStyle,
                rightIconButtonProps && iconRightStyle,
                disabledStyle,
                focusStyle,
                inputClassName,
              )}
              {...props}
            />
            {hasError && errorTextPosition === 'vertical' && <ErrorText />}
          </div>
          {rightIconButtonProps && (
            <span
              className='absolute inset-y-0 right-0 flex items-center pr-10'
              ref={iconButtonRef}>
              <IconButton disabled={disabled} {...rightIconButtonProps} />
            </span>
          )}
        </div>
        {hasError && errorTextPosition !== 'vertical' && <ErrorText />}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;

import Dropdown from 'afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useState } from 'react';

interface MonthCaptionProps {
  currentMonth: number;
  monthRange?: [number, number];
  onChangeMonth?: (month: number) => void;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
}

export default function MonthCaption({
  currentMonth,
  onChangeMonth,
  monthRange = [1, 12],
  customFocusScrollHandler,
  customSelectedScrollHandler,
}: MonthCaptionProps) {
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [monthToggle, setMonthToggle] = useState(false);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const monthsToKor = Array.from(
    { length: monthRange[1] - monthRange[0] + 1 },
    (_, i) => `${i + monthRange[0]}월`,
  );

  const handleMonthSelect = (month: number) => {
    setSelectedMonth(month);
    setMonthToggle(false);
    onChangeMonth?.(month);
  };

  return (
    <div className='month-dropdown-wrapper'>
      <button
        type='button'
        className='flex flex-row items-center gap-4'
        onClick={() => setMonthToggle(!monthToggle)}>
        <div className='whitespace-nowrap font-bold text-Header16 text-black700'>
          {monthsToKor[selectedMonth]}
        </div>
        <Icon name='chevron-down' size={16} color='black500' />
      </button>
      {monthToggle && (
        <Dropdown
          options={monthsToKor}
          selectedIndex={months.indexOf(selectedMonth + 1)}
          onSelect={(index) => handleMonthSelect(months[index])}
          handleToggle={() => setMonthToggle(!monthToggle)}
          width={115}
          wrapperClassName='month-dropdown'
          customFocusScrollHandler={customFocusScrollHandler}
          customSelectedScrollHandler={customSelectedScrollHandler}
        />
      )}
    </div>
  );
}

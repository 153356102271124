import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon, { type IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CSSProperties, ReactNode } from 'react';

type BaseTagSize = 'small' | 'big';

const BASE_TAG_SIZES: { [key in BaseTagSize]: BaseTagSize } = {
  small: 'small',
  big: 'big',
};

export const BASE_TAG_SIZES_ARR = Object.keys(BASE_TAG_SIZES) as BaseTagSize[];

export interface BaseTagProps {
  children: ReactNode;
  tagSize?: 'small' | 'big';
  borderWidth?: number;
  maxTextLength?: number | 'full';
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  iconProps?: IconProps;
  onClick?: (e?: React.MouseEvent) => void;
  className?: string;
  textClassName?: string;
  style?: CSSProperties;
}

export default function _BaseTag({
  children,
  tagSize = 'small',
  bgColor,
  maxTextLength = 10,
  borderWidth,
  borderColor,
  textColor,
  iconProps,
  onClick,
  className,
  textClassName,
  style,
}: BaseTagProps) {
  const textContent = SHARED_UTILS.text.extractTextFromChildren(children);
  const truncatedText =
    maxTextLength === 'full'
      ? textContent
      : SHARED_UTILS.text.truncateText(textContent, maxTextLength);

  return (
    <span
      className={customTwMerge(
        'inline-block h-fit w-fit select-none whitespace-nowrap rounded-r10',
        iconProps && 'flex items-center gap-4',
        onClick ? 'cursor-pointer' : 'cursor-default',
        className,
      )}
      onClick={onClick}
      style={{
        ...style,
        borderWidth,
        backgroundColor: bgColor,
        borderColor: borderColor ?? bgColor,
      }}>
      <div
        className={customTwMerge(
          tagSize === 'small' ? 'text-Caption9' : 'text-Body12',
          textClassName,
        )}
        style={{
          color: textColor,
        }}>
        {truncatedText}
      </div>
      {iconProps && <Icon {...iconProps} />}
    </span>
  );
}

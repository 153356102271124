import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { useTabContext } from '../contexts/tab-context';

interface TabProps<T> extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'value'> {
  label: ReactNode;
  value: T;
}
export interface ButtonTabProps<T> extends TabProps<T> {
  hasBadge?: boolean;
  subText?: string;
  className?: string;
}

export default function ButtonTab<T>({
  label,
  value,
  className,
  hasBadge = false,
  subText,
  ...props
}: ButtonTabProps<T>) {
  const { onClick, selectedValue } = useTabContext();

  return (
    <button
      role='tab'
      type='button'
      className={customTwMerge(
        'relative w-full min-w-90 cursor-pointer bg-transparent py-8 pr-16 pl-20 text-Body14 text-black200 hover:text-black700 focus:outline-none focus-visible:text-black700 aria-selected:text-Header14 aria-selected:text-black700',
        className,
      )}
      onClick={(e: React.MouseEvent) => {
        if (props.disabled) {
          return;
        }
        onClick(value, e);
      }}
      aria-selected={selectedValue === value}
      {...props}>
      {label}
      {subText && <span className='ml-10 text-Body12 text-black200'>{subText}</span>}
      {hasBadge && <span className='absolute top-0 right-0 h-6 w-6 rounded-full bg-red500' />}
    </button>
  );
}

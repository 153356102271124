import { type ReactNode, isValidElement } from 'react';

export const extractTextFromChildren = (children: ReactNode): string => {
  if (typeof children === 'string') {
    return children;
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join('');
  }

  if (isValidElement(children)) {
    return extractTextFromChildren(children.props.children);
  }

  return '';
};

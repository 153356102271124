export const calculateByteSize = (str: string): number => {
  let byteSize = 0;

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);

    if (charCode <= 0x7f) {
      byteSize += 1;
    } else if (charCode <= 0x7ff) {
      byteSize += 2;
    } else if (charCode >= 0xd800 && charCode <= 0xdbff) {
      byteSize += 4;
      i++;
    } else if (charCode <= 0xffff) {
      byteSize += 3;
    } else {
      byteSize += 4;
    }
  }

  return byteSize;
};

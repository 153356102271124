import { convertHexToRgb } from './convert-hex-to-rgb';
import { convertRgbToHsb } from './convert-rgb-to-hsb';

export const color = {
  /**
   * 16진수 색상 값을 RGB로 변환합니다.
   *
   * 이 함수는 16진수 색상 값을 받아서 RGB 값으로 변환합니다.
   *
   * @param {string} hex - 16진수 색상 값 (예: '#FFFFFF').
   * @returns {string} RGB 값 (예: 'rgb(255, 255, 255)').
   *
   * @example
   * // 16진수 색상 '#FFFFFF'를 'rgb(255, 255, 255)'로 변환합니다.
   * convertHexToRgb('#FFFFFF');
   */
  convertHexToRgb,

  /**
   * RGB 값을 HSB로 변환합니다.
   *
   * 이 함수는 RGB 값을 받아서 HSB 값으로 변환합니다.
   *
   * @param {string} rgb - RGB 값 (예: 'rgb(255, 255, 255)').
   * @returns {string} HSB 값 (예: 'hsb(0, 0%, 100%)').
   *
   * @example
   * // RGB 값 'rgb(255, 255, 255)'를 'hsb(0, 0%, 100%)'로 변환합니다.
   * rgbToHsb('rgb(255, 255, 255)');
   */
  convertRgbToHsb,
};

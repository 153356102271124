import { extendTailwindMerge } from 'tailwind-merge';
import { Color } from '../styles/color';
import { Radius } from '../styles/radius';
import { Text } from '../styles/text';
export const customTwMerge = extendTailwindMerge({
  override: {
    classGroups: {
      position: ['static', 'fixed', 'absolute', 'relative', 'sticky'],
      overflow: [{ overflow: ['auto', 'hidden', 'visible', 'scroll'] }],
      rounded: Object.keys(Radius).map((key) => `rounded-${key}`),
      'text-color': Object.keys(Color).map((key) => `text-${key}`),
      'font-size': Object.keys(Text).map((text) => `${text.replace('.', '')}`),
      'font-weight': Object.keys(Text).map((text) => `${text.replace('.', '')}`),
      leading: Object.keys(Text).map((text) => `${text.replace('.', '')}`),
      'max-h': ['none', 'full', 'min', 'max', 'fit'],
      'outline-style': ['solid', 'dashed', 'dotted', 'double', 'none'],
    },
  },
});

import type { CSSProperties, MouseEvent, ReactNode } from 'react';

export interface OffsetOption {
  event: MouseEvent;
  xOffset: number;
  yOffset: number;
}

export enum PositionType {
  MOUSE_EVENT = 'mouseEvent',
  ELEMENT_POSITION = 'elementPosition',
}

export interface ContextMenuOption {
  customId?: string;
  onClose?: VoidFunction;
  positionType?: PositionType;
  xOffset?: number; // basePosition을 기준으로 지정값 만큼 x축 이동
  yOffset?: number; // basePosition을 기준으로 지정값 만큼 y축 이동
  backdropStyle?: CSSProperties;
}

export interface ContextMenuListProps {
  id: string;
  parent: Element;
  content: ReactNode;
  portalStyle: CSSProperties;
  onClose: VoidFunction;
  positionType: PositionType;
  offsetOption: OffsetOption;
  backdropStyle?: CSSProperties;
}

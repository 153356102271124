import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoColorSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 25"
    {...props}
  >
    <path
      fill="#007AFF"
      d="M17.878 0H1.218C.545 0 0 .544 0 1.214v16.849c0 .67.546 1.214 1.217 1.214h16.66c.672 0 1.218-.544 1.218-1.214V1.214c0-.67-.546-1.214-1.217-1.214m-3.936 11.09h-2.94v2.933h-2.91V11.09h-2.94V8.187h2.94V5.254h2.91v2.933h2.94z"
    />
    <path
      fill="#96E6FD"
      d="M17.878.48 6.37 4.66c-.672.242-1.218.984-1.218 1.656v1.87h2.94V5.255h2.91v2.933h2.94v2.903h-2.94v2.933h-2.91V11.09h-2.94v12.075c0 .67.546 1.017 1.218.771l11.508-4.179c.671-.242 1.217-.984 1.217-1.657V1.254c0-.67-.546-1.017-1.217-.771z"
    />
    <path
      fill="#0AF"
      d="M17.878.48 6.37 4.66c-.672.242-1.218.984-1.218 1.656v12.961h12.726c.671 0 1.217-.544 1.217-1.214V1.254c0-.67-.546-1.017-1.217-.771zm-3.935 10.61h-2.94v2.933h-2.91V11.09h-2.94V8.187h2.94V5.254h2.91v2.933h2.94z"
    />
  </svg>
);
export default SvgLogoColorSymbol;

import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ReactNode } from 'react';

export interface LabelTextProps {
  children: ReactNode;
  className?: string;
  isRequired?: boolean;
  width?: number | string;
  indicatorClassName?: string;
  textClassName?: string;
}

export default function LabelText({
  children,
  className,
  isRequired = false,
  width,
  indicatorClassName,
  textClassName,
}: LabelTextProps) {
  return (
    <div className='flex items-center'>
      <div
        className={customTwMerge('flex flex-shrink-0 gap-2', className)}
        style={{
          width: SHARED_UTILS.css.getCssSizeValue(width),
        }}>
        <span className={customTwMerge('text-Header12 text-black500', textClassName)}>
          {children}
        </span>
        {isRequired && (
          <div
            className={customTwMerge(
              'w-[5px] flex-center text-Body10 text-red500',
              indicatorClassName,
            )}>
            *
          </div>
        )}
      </div>
    </div>
  );
}

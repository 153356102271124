import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { OverlayPageListProps } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.type';
import { useEffect, useRef } from 'react';
import { overlayPageService } from './OverlayPage.service';

export default function OverlayPage({
  id,
  children,
  onClose,
  position,
  className,
}: OverlayPageListProps) {
  const portalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const onCloseOverlayPage = () => {
    if (onClose) {
      return onClose();
    }
    return overlayPageService.pop();
  };

  useClickOutside({
    id,
    ref: portalRef,
    contentRef,
    onClose: onCloseOverlayPage,
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={customTwMerge('fixed z-10 select-none', className)}
      style={{
        left: position?.left ?? 0,
        top: position?.top ?? 0,
      }}>
      {children}
    </div>
  );
}
